import {getYourKnowledge, mapValues, getZoneYourAccess} from "./utils.js";
export function cardToVCard(card, pov) {
  const {tag, cardBack} = card;
  const yourKnowledge = getYourKnowledge(card, pov);
  if (tag === "cardInBunch") {
    return {
      tag,
      yourKnowledge,
      cardBack
    };
  } else if (tag === "cardInRegion") {
    const {facing, rotation, vars, attachments} = card;
    return {
      tag,
      yourKnowledge,
      cardBack,
      facing,
      rotation,
      vars,
      attachedCards: attachments.cards.map((attachment) => cardToVCard(attachment, pov))
    };
  } else {
    throw new Error(`tag must be cardInBunch or cardInRegion but is ${tag}`);
  }
}
export function zoneToVZone(zone, pov) {
  const {zoneKind, access, cards, owner, slug} = zone;
  const yourAccess = getZoneYourAccess(access, pov);
  const shouldTranslateCards = zoneKind === "region" || yourAccess === "accessible";
  return {
    zoneKind,
    yourAccess,
    access,
    owner,
    slug,
    nCards: cards.length,
    cards: shouldTranslateCards ? cards.map((card) => cardToVCard(card, pov)) : null,
    topCardBack: cards.length > 0 ? cards[0].cardBack : null
  };
}
export function historyEntryToVHistoryEntry(historyEntry, pov) {
  const {entryType} = historyEntry;
  if (entryType === "logLine") {
    const {logLine} = historyEntry;
    const {args} = logLine;
    const replacedArgs = mapValues(args, (value, argName) => {
      if (argName[0] === "r") {
        return value;
      } else if (argName[0] === "c") {
        const card = value;
        const yourKnowledge = getYourKnowledge(card, pov);
        return yourKnowledge;
      } else {
        return value;
      }
    });
    return {
      ...historyEntry,
      logLine: {
        ...logLine,
        args: replacedArgs
      }
    };
  } else {
    return historyEntry;
  }
}
export function stateToVState(state, pov) {
  if (!(pov === "spec" || state.roleToPlayerName[pov])) {
    throw new Error(`Must convert state to vState only for spectator or a player: ${pov}`);
  }
  return {
    pov,
    roleToPlayerName: state.roleToPlayerName,
    roles: state.roles,
    zones: mapValues(state.zones, (zone) => zoneToVZone(zone, pov)),
    vars: state.vars,
    historyEntries: state.historyEntries.map((historyEntry) => historyEntryToVHistoryEntry(historyEntry, pov))
  };
}
