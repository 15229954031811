import React from "../../../_snowpack/pkg/react.js";
import {useContext} from "../../../_snowpack/pkg/react.js";
import clsx from "../../../_snowpack/pkg/clsx.js";
import {useSelector, useDispatch} from "../../../_snowpack/pkg/react-redux.js";
import {getCardSrc, getCardBackSrc, imageNameFromCid} from "../../utils.js";
import {BigCardContext} from "../BigCardProvider.js";
import {CardInfoBox} from "../CardInfoBox.js";
import {MvPopupOrderMenu} from "./MvOrderMenu.js";
import {
  orderMenuDwim,
  createSelectOrderMenuIsOpenForDifferentSource,
  createSelectOrderMenuIsOpenForSource
} from "../../slices/orderMenu/orderMenuSlice.js";
import {createSelectVOrdersBySource} from "../../slices/mv/mvSlice.js";
import {makeCardVSource, mvDefaultCardVarValues} from "../../../_snowpack/link/engine/src/main.js";
import {CardRow} from "../CardRow.js";
import {MvCardList} from "./MvCardList.js";
export function MvCardVarBoxes(props) {
  const {vars} = props;
  const varNames = Object.keys(mvDefaultCardVarValues);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, varNames.map((varName) => {
    const value = vars[varName];
    if (value === void 0 || value === mvDefaultCardVarValues[varName]) {
      return null;
    }
    return /* @__PURE__ */ React.createElement(CardInfoBox, {
      key: varName,
      kind: varName,
      value
    });
  }));
}
export function MvSmallCard(props) {
  const {
    yourKnowledge,
    cardBack,
    facing = null,
    rotation = null,
    vars = null,
    stickOrderMenuToBottom,
    isInert,
    attachedCards
  } = props;
  const cid = yourKnowledge === null ? null : yourKnowledge.cid;
  const source = cid === null ? null : makeCardVSource(cid);
  const vOrders = useSelector(createSelectVOrdersBySource(source));
  const isActivatable = vOrders.length > 0 && !isInert;
  const imageName = imageNameFromCid(cid, {ignoreIfNotFound: true});
  let isKnownFacedown = false;
  let src = facing === "facedown" ? getCardBackSrc(cardBack) : getCardSrc(imageName);
  if (facing === "facedown" && yourKnowledge && yourKnowledge.knowledgeType === "full") {
    src = getCardSrc(imageName);
    isKnownFacedown = true;
  }
  const nameLabel = facing === "facedown" && cid ? cid : null;
  const {isActing, isBlocking, isTarget} = vars || {};
  const {setCursorOnTrigger} = useContext(BigCardContext);
  const dispatch = useDispatch();
  const handleClick = () => {
    if (isInert) {
      return;
    }
    if (vOrders.length === 0) {
      return;
    }
    dispatch(orderMenuDwim({source}));
  };
  const anotherOrderMenuIsOpen = useSelector(createSelectOrderMenuIsOpenForDifferentSource(source));
  const myOrderMenuIsOpen = useSelector(createSelectOrderMenuIsOpenForSource(source));
  const hasAttachments = attachedCards && attachedCards.length > 0;
  return /* @__PURE__ */ React.createElement("div", {
    className: clsx("CardOuter", {myOrderMenuIsOpen})
  }, /* @__PURE__ */ React.createElement("div", {
    className: clsx("Card", {
      isLocked: rotation === "locked",
      isActivatable,
      myOrderMenuIsOpen,
      anotherOrderMenuIsOpen,
      hasAttachments,
      isActing,
      isBlocking,
      isTarget,
      isKnownFacedown
    }),
    onMouseEnter: (event) => setCursorOnTrigger(true, imageName, event),
    onMouseLeave: () => setCursorOnTrigger(false)
  }, /* @__PURE__ */ React.createElement("img", {
    src,
    alt: cid,
    onClick: handleClick
  }), /* @__PURE__ */ React.createElement(CardInfoBox, {
    kind: "nameLabel",
    value: nameLabel
  }), vars ? /* @__PURE__ */ React.createElement(MvCardVarBoxes, {
    vars
  }) : null), source !== null && !isInert ? /* @__PURE__ */ React.createElement(MvPopupOrderMenu, {
    source,
    stickToBottom: stickOrderMenuToBottom
  }) : null, hasAttachments && /* @__PURE__ */ React.createElement(CardRow, {
    isAttachments: true
  }, /* @__PURE__ */ React.createElement(MvCardList, {
    cards: attachedCards
  })));
}
