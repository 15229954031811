export class ActionError extends Error {
  constructor(message) {
    super(message);
    Error.captureStackTrace(this, ActionError);
    Object.setPrototypeOf(this, ActionError.prototype);
  }
}
export class EError extends Error {
  constructor(message, context) {
    const extendedMessage = `${message} - ${JSON.stringify(context)}`;
    super(extendedMessage);
    Error.captureStackTrace(this, EError);
    Object.setPrototypeOf(this, EError.prototype);
  }
}
