import React from "../../_snowpack/pkg/react.js";
import clsx from "../../_snowpack/pkg/clsx.js";
export function Tab(props) {
  const {tab, setTab, value, text} = props;
  const href = `#${value}`;
  const isActive = tab === value;
  function onTabClick(e) {
    e.preventDefault();
    setTab(value);
  }
  const className = clsx("Tab", {isActive});
  return /* @__PURE__ */ React.createElement("li", {
    className,
    onClick: (e) => onTabClick(e)
  }, /* @__PURE__ */ React.createElement("a", {
    href
  }, text));
}
