export function sendFilledOrder(filledOrder) {
  return {
    type: 'sendFilledOrder',
    filledOrder,
  };
}

export function startNextStep(nextStep, partiallyFilledOrder) {
  return {
    type: 'startNextStep',
    nextStep,
    partiallyFilledOrder,
  };
}

export function cancelStep() {
  return {
    type: 'cancelStep',
  };
}

export function closeOrderMenu() {
  return {
    type: 'closeOrderMenu',
  };
}

export function orderMenuDwim(source) {
  return {
    type: 'orderMenuDwim',
    source,
  };
}

export function setCursorOnBigCard(value) {
  return {
    type: 'setCursorOnBigCard',
    value,
  };
}

export function setCursorOnTrigger(value, imageName) {
  return {
    type: 'setCursorOnTrigger',
    value,
    imageName,
  };
}

export function setPileExpanded(zoneName, value) {
  return {
    type: 'setPileExpanded',
    zoneName,
    value,
  };
}

export function sendMessage(message) {
  return {
    type: 'sendMessage',
    message,
  };
}

export function setConnectionStatus(connectionStatus) {
  return {
    type: 'setConnectionStatus',
    connectionStatus,
  };
}

export function clearGameId() {
  return {
    type: 'clearGameId',
  };
}
