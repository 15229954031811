import {mapValues} from "./utils.js";
import {ActionError, EError} from "./errors.js";
export function createActionGroup(actionApplicators) {
  const actionCreators = mapValues(actionApplicators, (_applicator, actionType) => {
    return (...args) => ({
      actionType,
      args
    });
  });
  return {
    actionCreators,
    actionApplicators
  };
}
const allGroups = [];
export function registerActionGroup(group) {
  allGroups.push(group);
}
export function applyAction(state, action, ...otherArgs) {
  if (otherArgs.length > 0) {
    throw new EError("calling applyAction with extra args, must have only 2", otherArgs);
  }
  const {actionType} = action;
  const group = allGroups.find((group2) => !!group2.actionApplicators[actionType]);
  if (!group) {
    console.warn("Unrecognized actionType of action", action);
    return {next: state, delta: null};
  }
  const applicator = group.actionApplicators[actionType];
  try {
    const aaResult = applicator(state, ...action.args);
    return aaResult;
  } catch (e) {
    if (e instanceof ActionError) {
      throw new EError(e.message, action);
    } else {
      throw e;
    }
  }
}
