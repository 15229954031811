import React from "../../_snowpack/pkg/react.js";
import {useContext} from "../../_snowpack/pkg/react.js";
import clsx from "../../_snowpack/pkg/clsx.js";
import {getCardSrc} from "../utils.js";
import {BigCardContext} from "./BigCardProvider.js";
export function ContextBasedBigCard(props) {
  let {isOpen, imageName, setCursorOnBigCard, isLeftSide} = useContext(BigCardContext);
  const className = clsx("BigCard", {
    isHidden: !isOpen,
    isLeftSide
  });
  return /* @__PURE__ */ React.createElement("div", {
    className
  }, isOpen && /* @__PURE__ */ React.createElement("img", {
    alt: imageName,
    src: getCardSrc(imageName),
    onMouseEnter: () => setCursorOnBigCard(true),
    onMouseLeave: () => setCursorOnBigCard(false)
  }));
}
