import {
  removeCardAtLocationCtx,
  addCardToLocationCtx,
  ensureCardInBunch,
  ensureCardInRegion,
  updateCardAtLocation,
  registerCardChangeCtx,
  saveToStashCtx
} from "./actionHelpers.js";
import {dc} from "./deltas.js";
import {ActionError} from "./errors.js";
import {createCardRegistry} from "./CardRegistry.js";
import {
  getZoneOfLocation,
  createAttachmentsRegion,
  getZone,
  findCardLocation,
  shuffleArray,
  mapValues,
  getCardByLocation
} from "./utils.js";
import produce from "../../../pkg/immer.js";
import {
  createActionGroup,
  registerActionGroup,
  applyAction
} from "./actionsInfrastructure.js";
export const foundationGroup = createActionGroup({
  nop(state) {
    return {
      next: state,
      delta: dc.empty()
    };
  },
  addPlayer(state, playerName, role) {
    return {
      next: produce(state, (draft) => {
        draft.roleToPlayerName[role] = playerName;
        draft.roles.push(role);
        draft.roleToCardRegistry[role] = createCardRegistry(role);
      }),
      delta: dc.playerAdded(playerName, role)
    };
  },
  addHistoryEntry(state, historyEntry) {
    const next = produce(state, (draftState) => {
      draftState.historyEntries.push(historyEntry);
    });
    const delta = dc.historyEntryAdded(historyEntry);
    return {
      next,
      delta
    };
  },
  createZone(state, zoneKind, owner, slug, access, defaultCardBack) {
    return {
      next: produce(state, (draft) => {
        const zoneName = owner ? `${owner}:${slug}` : slug;
        const zone = {
          zoneKind,
          owner,
          slug,
          access,
          cards: [],
          defaultCardBack
        };
        draft.zones[zoneName] = zone;
      }),
      delta: dc.zoneCreated(zoneKind, owner, slug, access, defaultCardBack)
    };
  },
  seq(state, ...actions) {
    let currentState = state;
    const childDeltas = [];
    actions.forEach((childAction) => {
      const {next, delta} = applyAction(currentState, childAction);
      currentState = next;
      if (delta.deltaType !== "empty") {
        childDeltas.push(delta);
      }
    });
    return {
      next: currentState,
      delta: childDeltas.length === 0 ? dc.empty() : childDeltas.length === 1 ? childDeltas[0] : dc.seq(childDeltas)
    };
  },
  repeat(state, childAction, repeatCount) {
    const childActions = [];
    for (let i = 0; i < repeatCount; ++i) {
      childActions.push(childAction);
    }
    return applyAction(state, fa.seq(...childActions));
  },
  createCard(state, base, zoneName, options = {}) {
    const zone = getZone(state, zoneName);
    if (!zone) {
      throw new ActionError(`zone not found: ${zoneName}`);
    }
    const {owner, defaultCardBack} = zone;
    if (!owner) {
      throw new ActionError(`cannot create card in zone without owner`);
    }
    if (!defaultCardBack) {
      throw new ActionError(`cannot create card in zone without defaultCardBack`);
    }
    const tag = zone.zoneKind === "bunch" ? "cardInBunch" : "cardInRegion";
    const facing = tag === "cardInBunch" ? null : options.facing || "faceup";
    const rotation = tag === "cardInBunch" ? null : options.rotation || "ready";
    const roleToKnowledge = {};
    const {baseToCounter} = state;
    const counter = (baseToCounter[base] || 0) + 1;
    const sid = `${base}:${counter}`;
    const card = tag === "cardInBunch" ? {
      tag,
      sid,
      owner,
      cardBack: defaultCardBack,
      roleToKnowledge
    } : {
      tag,
      sid,
      owner,
      cardBack: defaultCardBack,
      roleToKnowledge,
      vars: {},
      facing,
      rotation,
      attachments: createAttachmentsRegion(sid, owner)
    };
    const newState = produce(state, (draftState) => {
      draftState.baseToCounter[base] = counter;
    });
    const ctx = {state: newState};
    const newCard = registerCardChangeCtx(ctx, card, facing, zone);
    const index = zone.cards.length;
    const location = {zoneName, index};
    addCardToLocationCtx(ctx, newCard, location);
    const delta = dc.cardCreated(newCard, location);
    return {
      next: ctx.state,
      delta
    };
  },
  changeCardFacing(state, sid, facing, options = {}) {
    const location = options.location || findCardLocation(state, sid);
    if (!location) {
      throw new ActionError(`card not found`);
    }
    const ctx = {state};
    let card = removeCardAtLocationCtx(ctx, location);
    if (card.tag !== "cardInRegion") {
      throw new ActionError(`card must be cardInRegion to change facing`);
    }
    card = registerCardChangeCtx(ctx, card, facing, getZoneOfLocation(state, location));
    const newCard = {
      ...card,
      facing
    };
    addCardToLocationCtx(ctx, newCard, location);
    const delta = dc.cardChangedFacing(newCard, facing, location);
    return {
      next: ctx.state,
      delta
    };
  },
  moveCard(state, sid, targetZoneName, options = {}) {
    const sourceLocation = options.sourceLocation || findCardLocation(state, sid);
    if (!sourceLocation) {
      throw new ActionError(`card not found`);
    }
    const targetZone = getZone(state, targetZoneName);
    if (!targetZone) {
      throw new ActionError(`target zone "${targetZoneName}" not found`);
    }
    const targetIndex = targetZone.cards.length;
    const targetLocation = {
      zoneName: targetZoneName,
      index: targetIndex
    };
    const ctx = {state};
    let card = removeCardAtLocationCtx(ctx, sourceLocation);
    let facing;
    if (targetZone.zoneKind === "bunch") {
      facing = null;
    } else {
      facing = options.facing || (card.tag === "cardInRegion" ? card.facing : null) || "faceup";
    }
    card = registerCardChangeCtx(ctx, card, facing, targetZone);
    let newCard;
    if (targetZone.zoneKind === "bunch") {
      newCard = ensureCardInBunch(card);
    } else {
      newCard = ensureCardInRegion(card, {
        facing,
        rotation: options.rotation
      });
    }
    addCardToLocationCtx(ctx, newCard, targetLocation);
    if (options.saveAs) {
      saveToStashCtx(ctx, options.saveAs, newCard);
    }
    if (options.saveTargetLocationAs) {
      saveToStashCtx(ctx, options.saveTargetLocationAs, targetLocation);
    }
    const delta = dc.cardMoved(newCard, sourceLocation, targetLocation);
    return {
      next: ctx.state,
      delta
    };
  },
  changeCardRotation(state, sid, rotation, options = {}) {
    const location = options.location || findCardLocation(state, sid);
    if (!location) {
      throw new ActionError(`card not found`);
    }
    const {next, newCard} = updateCardAtLocation(state, location, (card) => {
      if (card.tag !== "cardInRegion") {
        throw new ActionError(`card must be in a region`);
      }
      return {
        ...card,
        rotation
      };
    });
    const delta = dc.cardChangedRotation(newCard, rotation, location);
    return {
      next,
      delta
    };
  },
  attachCard(state, cardSid, hostSid, options = {}) {
    const location = options.cardLocation || findCardLocation(state, cardSid);
    if (!location) {
      throw new ActionError(`card not found`);
    }
    const hostLocation = findCardLocation(state, hostSid);
    if (!hostLocation) {
      throw new ActionError(`host card not found`);
    }
    const {zoneName: hostZoneName, index: hostCardIndex} = hostLocation;
    if (hostLocation.attachmentIndex !== void 0) {
      throw new ActionError("attaching to an attachment is not supported");
    }
    const hostZone = getZone(state, hostZoneName);
    if (hostZone.zoneKind !== "region") {
      throw new ActionError("cannot attach to a non-region card");
    }
    const hostCard = hostZone.cards[hostCardIndex];
    const attachmentIndex = hostCard.attachments.cards.length;
    const targetLocation = {
      zoneName: hostZoneName,
      index: hostCardIndex,
      attachmentIndex
    };
    const ctx = {state};
    let card = removeCardAtLocationCtx(ctx, location);
    card = registerCardChangeCtx(ctx, card, "faceup", getZoneOfLocation(state, targetLocation));
    const newCard = ensureCardInRegion(card);
    addCardToLocationCtx(ctx, newCard, targetLocation);
    if (options.saveCardAs) {
      saveToStashCtx(ctx, options.saveCardAs, newCard);
    }
    if (options.saveHostAs) {
      const newHostCard = getCardByLocation(ctx.state, hostLocation);
      saveToStashCtx(ctx, options.saveHostAs, newHostCard);
    }
    const delta = dc.cardMoved(newCard, location, targetLocation);
    return {
      next: ctx.state,
      delta
    };
  },
  setCardVars(state, sid, setters, options = {}) {
    const location = options.location || findCardLocation(state, sid);
    if (!location) {
      throw new ActionError(`card not found`);
    }
    const {next, newCard} = updateCardAtLocation(state, location, (card) => {
      if (card.tag !== "cardInRegion") {
        throw new ActionError(`card must be in region to have vars`);
      }
      return produce(card, (draftCard) => {
        Object.entries(setters).forEach(([varName, value]) => {
          draftCard.vars[varName] = value;
        });
      });
    });
    const ctx = {state: next};
    if (options.saveAs) {
      saveToStashCtx(ctx, options.saveAs, newCard);
    }
    const delta = dc.cardVarsSet(newCard, location, setters);
    return {
      next: ctx.state,
      delta
    };
  },
  changeCardVars(state, sid, changers, options = {}) {
    const location = options.location || findCardLocation(state, sid);
    if (!location) {
      throw new ActionError(`card not found`);
    }
    const ctx = {state};
    const {next, newCard} = updateCardAtLocation(state, location, (card) => {
      if (card.tag !== "cardInRegion") {
        throw new ActionError(`card must be in region to have vars`);
      }
      return produce(card, (draftCard) => {
        Object.entries(changers).forEach(([varName, value]) => {
          const current = draftCard.vars[varName];
          if (typeof current !== "number") {
            throw new ActionError(`cannot change non-number var ${varName}: ${current}`);
          }
          const newValue = current + value;
          draftCard.vars[varName] = newValue;
        });
      });
    });
    ctx.state = next;
    if (options.saveAs) {
      saveToStashCtx(ctx, options.saveAs, newCard);
    }
    if (options.saveNewValueAs) {
      const [varName, stashVarName] = options.saveNewValueAs;
      saveToStashCtx(ctx, stashVarName, newCard.vars[varName]);
    }
    const delta = dc.cardVarsChanged(newCard, location, changers);
    return {
      next: ctx.state,
      delta
    };
  },
  setVars(state, setters) {
    const next = produce(state, (draftState) => {
      Object.entries(setters).forEach(([varName, value]) => {
        draftState.vars[varName] = value;
      });
    });
    return {
      next,
      delta: dc.stateVarsSet(setters)
    };
  },
  changeVars(state, changers, options = {}) {
    const newValues = {};
    const next = produce(state, (draftState) => {
      Object.entries(changers).forEach(([varName, changer]) => {
        const current = draftState.vars[varName];
        if (typeof current !== "number") {
          throw new ActionError(`cannot change non-number var ${varName}`);
        }
        const value = current + changer;
        newValues[varName] = value;
        draftState.vars[varName] = value;
      });
    });
    const ctx = {state: next};
    if (options.saveNewValueAs) {
      const [varName, stashVarName] = options.saveNewValueAs;
      saveToStashCtx(ctx, stashVarName, newValues[varName]);
    }
    return {
      next: ctx.state,
      delta: dc.stateVarsChanged(changers, newValues)
    };
  },
  setSingleVar(state, varName, value) {
    return applyAction(state, fa.setVars({
      [varName]: value
    }));
  },
  changeSingleVar(state, varName, changer, options = {}) {
    const changeVarsOptions = options.saveNewValueAs ? {
      saveNewValueAs: [varName, options.saveNewValueAs]
    } : void 0;
    return applyAction(state, fa.changeVars({
      [varName]: changer
    }, changeVarsOptions));
  },
  setZoneAccess(state, zoneName, access) {
    const zone = getZone(state, zoneName);
    if (!zone) {
      throw new ActionError(`no zone by name`);
    }
    let newZone = {
      ...zone,
      access
    };
    const ctx = {state};
    const newCards = zone.cards.map((card) => {
      const facing = card.tag === "cardInBunch" ? null : card.facing;
      return registerCardChangeCtx(ctx, card, facing, newZone);
    });
    newZone = {
      ...newZone,
      cards: newCards
    };
    const next = produce(ctx.state, (draftState) => {
      draftState.zones[zoneName] = newZone;
    });
    const {zoneKind} = zone;
    const delta = dc.zoneAccessSet(zoneName, zoneKind, access, newZone.cards, zone.access);
    return {
      next,
      delta
    };
  },
  shuffleZone(state, zoneName) {
    const zone = getZone(state, zoneName);
    if (!zone) {
      throw new ActionError(`no zone by name`);
    }
    const newCards = shuffleArray(zone.cards);
    const newZone = produce(zone, (draftZone) => {
      draftZone.cards = newCards;
    });
    const next = produce(state, (draftState) => {
      draftState.zones[zoneName] = newZone;
    });
    const delta = dc.zoneRearranged(newZone);
    return {
      next,
      delta
    };
  },
  text(state, templateCode, argsDescriptor) {
    const args = mapValues(argsDescriptor, (argDescriptor, argName) => {
      let argValue;
      if ("value" in argDescriptor) {
        argValue = argDescriptor.value;
      } else if ("fromStash" in argDescriptor) {
        argValue = state.stash[argDescriptor.fromStash];
      } else {
        throw new ActionError("argDescriptor must be either value or fromStash");
      }
      return argValue;
    });
    console.log("args after transformation", args);
    const logLine = {
      templateCode,
      args
    };
    const historyEntry = {
      entryType: "logLine",
      logLine
    };
    return applyAction(state, fa.addHistoryEntry(historyEntry));
  }
});
registerActionGroup(foundationGroup);
export const fa = foundationGroup.actionCreators;
export function getStateAfterActions(state, ...actions) {
  const {next} = applyAction(state, fa.seq(...actions));
  return next;
}
