import React, {useEffect, useState} from "../../_snowpack/pkg/react.js";
import {Redirect} from "../../_snowpack/pkg/react-router-dom.js";
import {useSelector, useDispatch} from "../../_snowpack/pkg/react-redux.js";
import {useSendMessage} from "../hooks/useSendMessage.js";
import {
  selectGameId,
  selectConnectionStatus
} from "../slices/common/commonSlice.js";
import {clearGameId} from "../actions.js";
import {SetupSoloGameSection} from "./Home/SetupSoloGameSection.js";
import {ActiveSoloGamesSection} from "./Home/ActiveSoloGamesSection.js";
export function Home() {
  const sendMessage = useSendMessage();
  const [clicked, setClicked] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const gameId = useSelector(selectGameId);
  const dispatch = useDispatch();
  const connectionStatus = useSelector(selectConnectionStatus);
  useEffect(() => {
    dispatch(clearGameId());
    sendMessage({tag: "home/enter"});
  }, [dispatch]);
  useEffect(() => {
    if (gameId) {
      setShouldRedirect(true);
    }
  }, [gameId]);
  function handleStartDemoClick() {
    sendMessage({tag: "requestNewGame", gameTag: "mv"});
    if (!gameId) {
      return;
    }
    setClicked(true);
  }
  if (gameId && shouldRedirect) {
    const route = `/play/${gameId}`;
    return /* @__PURE__ */ React.createElement(Redirect, {
      push: true,
      to: route
    });
  }
  return /* @__PURE__ */ React.createElement("div", {
    className: "Home"
  }, /* @__PURE__ */ React.createElement("section", null, /* @__PURE__ */ React.createElement("h1", null, "Backways"), /* @__PURE__ */ React.createElement("p", null, "Play VtES with some automation."), /* @__PURE__ */ React.createElement("p", null, "Connection status: $", connectionStatus)), /* @__PURE__ */ React.createElement(SetupSoloGameSection, null), /* @__PURE__ */ React.createElement(ActiveSoloGamesSection, null));
}
