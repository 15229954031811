import React from "../../../_snowpack/pkg/react.js";
import {useSelector} from "../../../_snowpack/pkg/react-redux.js";
import {qualifiedName} from "../../../_snowpack/link/engine/src/main.js";
import {
  selectPlayerSectionRoles,
  selectRoles,
  selectYourRole,
  createSelectSingleStateVar
} from "../../slices/mv/mvSlice.js";
import {PlayArea} from "../PlayArea.js";
import {Overlay} from "../Overlay.js";
import {PlayerBadgesContainer} from "../PlayerBadgesContainer.js";
import {CurrentCardsContainer} from "../CurrentCardsContainer.js";
import {MvPlayerBadge} from "./MvPlayerBadge.js";
import {MvPlayerSection} from "./MvPlayerSection.js";
import {MvPlayerHand} from "./MvPlayerHand.js";
import {MvGeneralOrderMenu} from "./MvOrderMenu.js";
import {MvCurrentCardsColumn} from "./MvCurrentCardsColumn.js";
export function MvGame(props) {
  const roles = useSelector(selectRoles);
  const playerSectionRoles = useSelector(selectPlayerSectionRoles);
  const yourRole = useSelector(selectYourRole);
  const youAreOusted = useSelector(createSelectSingleStateVar(qualifiedName("ousted", yourRole)));
  if (!roles) {
    return null;
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Overlay, null, /* @__PURE__ */ React.createElement(PlayerBadgesContainer, null, roles.map((role) => /* @__PURE__ */ React.createElement(MvPlayerBadge, {
    key: role,
    role
  }))), /* @__PURE__ */ React.createElement(CurrentCardsContainer, null, /* @__PURE__ */ React.createElement(MvGeneralOrderMenu, null), roles.map((role) => {
    return /* @__PURE__ */ React.createElement(MvCurrentCardsColumn, {
      key: role,
      role
    });
  }))), /* @__PURE__ */ React.createElement(PlayArea, null, playerSectionRoles.map((role, index) => {
    return /* @__PURE__ */ React.createElement(MvPlayerSection, {
      key: role,
      role
    });
  }), youAreOusted ? null : /* @__PURE__ */ React.createElement("div", {
    className: "YourHandContainer"
  }, /* @__PURE__ */ React.createElement(MvPlayerHand, {
    owner: yourRole,
    stickOrderMenuToBottom: true
  }))));
}
