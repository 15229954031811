import React from "../../_snowpack/pkg/react.js";
import FocusTrap from "../../_snowpack/pkg/focus-trap-react.js";
export function PopupWrapper(props) {
  const {isActive, onDismiss, children} = props;
  const focusTrapOptions = {
    allowOutsideClick: (event) => {
      if (event.type === "click") {
        onDismiss();
      }
      return false;
    },
    escapeDeactivates: () => {
      onDismiss();
      return false;
    }
  };
  return /* @__PURE__ */ React.createElement(FocusTrap, {
    active: isActive,
    focusTrapOptions
  }, children);
}
