export const mvDefaultMinionVarValues = {
  stealth: 0,
  intercept: 0,
  strength: 1,
  bleed: 1,
  votes: 0
};
export const mvDefaultCardVarValues = {
  blood: 0,
  ...mvDefaultMinionVarValues
};
export const havingBloodSlugs = [
  "support",
  "ready",
  "torpor",
  "uncontrolled"
];
export const inPlaySlugs = ["support", "ready", "torpor"];
export const minionSlugs = ["ready", "torpor"];
