import {createSlice, createSelector} from "../../../_snowpack/pkg/@reduxjs/toolkit.js";
import {createSelectVOrdersBySource} from "../mv/mvSlice.js";
const orderMenuSlice = createSlice({
  name: "orderMenu",
  initialState: {
    isOpen: false,
    source: null
  },
  reducers: {
    openOrderMenu: (state, action) => {
      const {source} = action.payload;
      state.isOpen = true;
      state.source = source;
    },
    closeOrderMenu: (state, action) => {
      if (state.isOpen) {
        state.isOpen = false;
        state.source = null;
      }
    }
  }
});
export const {openOrderMenu, closeOrderMenu} = orderMenuSlice.actions;
export function orderMenuDwim(payload) {
  const {source} = payload;
  const selectVOrders = createSelectVOrdersBySource(source);
  return (dispatch, getState) => {
    const state = getState();
    const vOrders = selectVOrders(state);
    if (vOrders.length === 0) {
      return;
    }
    dispatch(openOrderMenu({source}));
  };
}
export const orderMenuReducer = orderMenuSlice.reducer;
export const selectOrderMenu = (state) => state.orderMenu;
export const selectOrderMenuIsOpen = createSelector(selectOrderMenu, (orderMenu) => orderMenu.isOpen);
export function createSelectOrderMenuIsOpenForSource(source) {
  return createSelector(selectOrderMenu, (orderMenu) => orderMenu.isOpen && orderMenu.source === source);
}
export function createSelectOrderMenuIsOpenForDifferentSource(source) {
  return createSelector(selectOrderMenu, (orderMenu) => orderMenu.isOpen && orderMenu.source !== source);
}
