import React from "../../../_snowpack/pkg/react.js";
import {useSelector} from "../../../_snowpack/pkg/react-redux.js";
import {CardColumn} from "../CardColumn.js";
import {MvCardList} from "./MvCardList.js";
import {
  createSelectPlayerNameByRole,
  createSelectZoneByOwnerAndSlug
} from "../../slices/mv/mvSlice.js";
export function MvCurrentCardsColumn(props) {
  const {role} = props;
  const playerName = useSelector(createSelectPlayerNameByRole(role));
  const zone = useSelector(createSelectZoneByOwnerAndSlug(role, "current"));
  const {cards} = zone;
  if (cards.length === 0) {
    return null;
  }
  return /* @__PURE__ */ React.createElement(CardColumn, {
    key: role,
    label: playerName
  }, /* @__PURE__ */ React.createElement(MvCardList, {
    cards,
    stickNextLevelMenuTo: "left"
  }));
}
