import React from "../../_snowpack/pkg/react.js";
export function PlayArea(props) {
  return /* @__PURE__ */ React.createElement("div", {
    className: "PlayArea"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "Scroller"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "Wrapper"
  }, props.children)));
}
