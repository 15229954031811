import React from "../../_snowpack/pkg/react.js";
import clsx from "../../_snowpack/pkg/clsx.js";
export function CardColumn(props) {
  const {label} = props;
  const className = clsx("CardColumn");
  return /* @__PURE__ */ React.createElement("div", {
    className
  }, /* @__PURE__ */ React.createElement("div", {
    className: "label"
  }, label), /* @__PURE__ */ React.createElement("div", {
    className: "contents"
  }, props.children));
}
