export const jyhadMinionPropertyDefaults = {
  stealth: 0,
  intercept: 0,
  strength: 1,
  bleed: 1,
  votes: 0
};
export const jyhadCardPropertyDefaults = {
  blood: 0,
  ...jyhadMinionPropertyDefaults
};
