import React from "../../../_snowpack/pkg/react.js";
import {useSelector} from "../../../_snowpack/pkg/react-redux.js";
import {PlayerSection} from "../PlayerSection.js";
import {
  createSelectPlayerNameByRole,
  selectYourRole
} from "../../slices/mv/mvSlice.js";
import {HRow} from "../HRow.js";
import {MvZoneCardPile} from "./MvZoneCardPile.js";
import {MvZoneCardRow} from "./MvZoneCardRow.js";
import {MvPlayerHand} from "./MvPlayerHand.js";
export function MvPlayerSection(props) {
  const {role} = props;
  const playerName = useSelector(createSelectPlayerNameByRole(role));
  const yourRole = useSelector(selectYourRole);
  return /* @__PURE__ */ React.createElement(PlayerSection, {
    playerName
  }, /* @__PURE__ */ React.createElement(HRow, null, role === yourRole ? null : /* @__PURE__ */ React.createElement("div", {
    className: "RowOfPiles"
  }, /* @__PURE__ */ React.createElement(MvPlayerHand, {
    owner: role
  })), /* @__PURE__ */ React.createElement("div", {
    className: "RowOfPiles"
  }, /* @__PURE__ */ React.createElement(MvZoneCardPile, {
    owner: role,
    slug: "ashHeap"
  }), /* @__PURE__ */ React.createElement(MvZoneCardPile, {
    owner: role,
    slug: "crypt"
  }), /* @__PURE__ */ React.createElement(MvZoneCardPile, {
    owner: role,
    slug: "library"
  })), /* @__PURE__ */ React.createElement(MvZoneCardRow, {
    owner: role,
    slug: "uncontrolled"
  }), /* @__PURE__ */ React.createElement(MvZoneCardRow, {
    hasSpacedCards: true,
    owner: role,
    slug: "torpor"
  })), /* @__PURE__ */ React.createElement(HRow, null, /* @__PURE__ */ React.createElement(MvZoneCardRow, {
    hasSpacedCards: true,
    owner: role,
    slug: "support"
  }), /* @__PURE__ */ React.createElement(MvZoneCardRow, {
    hasSpacedCards: true,
    owner: role,
    slug: "ready"
  })));
}
