import React from "../../../_snowpack/pkg/react.js";
import {useSelector} from "../../../_snowpack/pkg/react-redux.js";
import {createSelectZoneByOwnerAndSlug} from "../../slices/mv/mvSlice.js";
import {MvHiddenCardPile} from "./MvZoneCardPile.js";
import {CardRow} from "../CardRow.js";
import {MvCardList} from "./MvCardList.js";
export function MvPlayerHand(props) {
  const {owner, stickOrderMenuToBottom} = props;
  const slug = "hand";
  const zone = useSelector(createSelectZoneByOwnerAndSlug(owner, slug));
  const {yourAccess, cards} = zone;
  if (yourAccess === "accessible") {
    return /* @__PURE__ */ React.createElement(CardRow, {
      isAccessibleHand: true
    }, /* @__PURE__ */ React.createElement(MvCardList, {
      cards,
      stickOrderMenuToBottom
    }));
  } else {
    const {nCards, topCardBack} = zone;
    return /* @__PURE__ */ React.createElement(MvHiddenCardPile, {
      topCardBack,
      pileNameForHumans: "Hand",
      nCards,
      maxSize: 7,
      owner,
      slug
    });
  }
}
