import {getStateVar, qualifiedName} from "../utils.js";
export function getActivePlayerRole(state) {
  return getStateVar(state, "activePlayerRole");
}
export function getPlayingRoles(state) {
  return state.roles.filter((role) => !getStateVar(state, qualifiedName("ousted", role), 0));
}
export function getNonActiveRoles(state) {
  const activePlayerRole = getActivePlayerRole(state);
  return getPlayingRoles(state).filter((role) => role !== activePlayerRole);
}
