import React from "../../../_snowpack/pkg/react.js";
import {useState, useEffect} from "../../../_snowpack/pkg/react.js";
import clsx from "../../../_snowpack/pkg/clsx.js";
import {useSelector, useDispatch} from "../../../_snowpack/pkg/react-redux.js";
import {
  createSelectZoneByOwnerAndSlug,
  selectYourRole,
  createSelectSingleStateVar
} from "../../slices/mv/mvSlice.js";
import {getZoneNameForHumans} from "../../translations.js";
import {PopupWrapper} from "../PopupWrapper.js";
import {MvCardList} from "./MvCardList.js";
import {makePileVSource} from "../../../_snowpack/link/engine/src/main.js";
import {
  orderMenuDwim,
  createSelectOrderMenuIsOpenForDifferentSource,
  createSelectOrderMenuIsOpenForSource
} from "../../slices/orderMenu/orderMenuSlice.js";
import {createSelectVOrdersBySource} from "../../slices/mv/mvSlice.js";
import {MvPopupOrderMenu} from "./MvOrderMenu.js";
import {getCardBackSrc} from "../../utils.js";
import {Button} from "../Button.js";
export function MvPilePreview(props) {
  const {cards, onPilePreviewClick} = props;
  return /* @__PURE__ */ React.createElement("div", {
    className: clsx("PilePreview"),
    onClick: onPilePreviewClick
  }, /* @__PURE__ */ React.createElement(MvCardList, {
    cards,
    isInert: true
  }));
}
export function MvOpenCardPile(props) {
  const {pileNameForHumans, owner, slug} = props;
  const [isExpanded, setExpanded] = useState(false);
  const yourRole = useSelector(selectYourRole);
  const searchingLibraryRole = useSelector(createSelectSingleStateVar("searchingLibraryRole"));
  const searchingCryptRole = useSelector(createSelectSingleStateVar("searchingCryptRole"));
  const zone = useSelector(createSelectZoneByOwnerAndSlug(owner, slug));
  const {cards} = zone;
  const nCards = cards.length;
  const label = `(${nCards})`;
  function handlePilePreviewClick() {
    setExpanded(!isExpanded);
  }
  function handleDismiss() {
    setExpanded(false);
  }
  useEffect(() => {
    if (yourRole === searchingLibraryRole && owner === yourRole && slug === "library" || yourRole === searchingCryptRole && owner === yourRole && slug === "crypt") {
      setExpanded(true);
    }
  }, [yourRole, searchingLibraryRole, searchingCryptRole, owner, slug]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "OpenCardPile"
  }, /* @__PURE__ */ React.createElement(MvPilePreview, {
    cards,
    onPilePreviewClick: handlePilePreviewClick
  }), /* @__PURE__ */ React.createElement("div", {
    className: "label"
  }, /* @__PURE__ */ React.createElement("div", null, pileNameForHumans, /* @__PURE__ */ React.createElement("br", null), label)), isExpanded && /* @__PURE__ */ React.createElement(PopupWrapper, {
    isActive: true,
    onDismiss: handleDismiss
  }, /* @__PURE__ */ React.createElement("div", {
    className: clsx("Contents", {isExpanded})
  }, /* @__PURE__ */ React.createElement(MvCardList, {
    cards
  }), /* @__PURE__ */ React.createElement(Button, {
    isSecondary: true,
    className: "closeButton",
    onClick: handleDismiss
  }, "✕"))));
}
export function MvHiddenCardPile(props) {
  const {
    pileNameForHumans,
    topCardBack,
    nCards,
    maxSize,
    owner,
    slug,
    stickOrderMenuToBottom
  } = props;
  const after = maxSize !== void 0 ? ` / ${maxSize}` : "";
  const counts = `(${nCards}${after})`;
  const source = makePileVSource(owner, slug);
  const vOrders = useSelector(createSelectVOrdersBySource(source));
  const isActivatable = vOrders.length > 0;
  const anotherOrderMenuIsOpen = useSelector(createSelectOrderMenuIsOpenForDifferentSource(source));
  const myOrderMenuIsOpen = useSelector(createSelectOrderMenuIsOpenForSource(source));
  const dispatch = useDispatch();
  const handleClick = () => {
    if (vOrders.length === 0) {
      return;
    }
    dispatch(orderMenuDwim({source}));
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "CardOuter"
  }, /* @__PURE__ */ React.createElement("div", {
    className: clsx("Card HiddenCardPile", {
      isActivatable,
      myOrderMenuIsOpen,
      anotherOrderMenuIsOpen
    })
  }, nCards > 0 ? /* @__PURE__ */ React.createElement("img", {
    alt: "top card",
    src: getCardBackSrc(topCardBack),
    onClick: handleClick
  }) : /* @__PURE__ */ React.createElement("div", {
    className: "placeholder",
    onClick: handleClick
  }), /* @__PURE__ */ React.createElement("div", {
    className: "label"
  }, /* @__PURE__ */ React.createElement("div", null, pileNameForHumans, /* @__PURE__ */ React.createElement("br", null), counts))), /* @__PURE__ */ React.createElement(MvPopupOrderMenu, {
    source,
    stickToBottom: stickOrderMenuToBottom
  }));
}
export function MvZoneCardPile(props) {
  const {owner, slug} = props;
  const zone = useSelector(createSelectZoneByOwnerAndSlug(owner, slug));
  const pileNameForHumans = getZoneNameForHumans(slug);
  const {yourAccess, nCards, topCardBack} = zone;
  if (yourAccess === "accessible") {
    return /* @__PURE__ */ React.createElement(MvOpenCardPile, {
      pileNameForHumans,
      owner,
      slug
    });
  } else {
    return /* @__PURE__ */ React.createElement(MvHiddenCardPile, {
      topCardBack,
      pileNameForHumans,
      nCards,
      owner,
      slug
    });
  }
}
