import React, {useRef, useCallback, useEffect} from "../../_snowpack/pkg/react.js";
import {Sep} from "./Sep.js";
import {LogLine} from "./LogLine.js";
import {formatTimestamp} from "../utils.js";
export function History(props) {
  const {onTextEntered, historyEntries, gameTag, yourRole} = props;
  const inputRef = useRef(null);
  const onKeyPress = useCallback((event) => {
    if (event.key !== "Enter") {
      return;
    }
    const input = inputRef.current;
    const text = event.target.value;
    onTextEntered(text);
    input.value = "";
    input.focus();
  }, [onTextEntered]);
  const scrollerRef = useRef(null);
  const atBottomRef = useRef(true);
  useEffect(() => {
    if (!atBottomRef.current) {
      return;
    }
    const el = scrollerRef.current;
    el.scrollTop = el.scrollHeight;
  }, [historyEntries, scrollerRef]);
  const handleScroll = () => {
    const el = scrollerRef.current;
    const atBottom = el.scrollTop + el.offsetHeight === el.scrollHeight;
    atBottomRef.current = atBottom;
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "History"
  }, /* @__PURE__ */ React.createElement("ul", {
    ref: scrollerRef,
    onScroll: handleScroll
  }, !historyEntries ? null : historyEntries.map((historyEntry, index) => {
    const {entryType} = historyEntry;
    switch (entryType) {
      case "chatLine": {
        const {chatLine} = historyEntry;
        const {username, timestamp, text} = chatLine;
        return /* @__PURE__ */ React.createElement("li", {
          key: index,
          className: "chatLine"
        }, /* @__PURE__ */ React.createElement("b", null, username), /* @__PURE__ */ React.createElement(Sep, null), /* @__PURE__ */ React.createElement("span", {
          className: "timestamp"
        }, formatTimestamp(timestamp)), /* @__PURE__ */ React.createElement(Sep, null), /* @__PURE__ */ React.createElement("span", {
          className: "text"
        }, text));
      }
      case "logLine": {
        const clientLogLine = historyEntry.logLine;
        return /* @__PURE__ */ React.createElement(LogLine, {
          key: index,
          clientLogLine,
          gameTag,
          yourRole
        });
      }
      default: {
        return /* @__PURE__ */ React.createElement("li", {
          key: index,
          className: "unknown"
        }, "(unknown text entry ", entryType, ")");
      }
    }
  })), /* @__PURE__ */ React.createElement("input", {
    ref: inputRef,
    autoComplete: "off",
    onKeyPress
  }));
}
