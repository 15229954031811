import {baseToCardInfo} from "./data/cards.js";
export function varNameForHumans(varName, roleToPlayerName) {
  const parts = varName.split(":");
  if (parts.length === 2) {
    const [role, shortVarName] = parts;
    const playerName = roleToPlayerName[role];
    return `${playerName}'s ${shortVarName}`;
  } else {
    return varName;
  }
}
export function cardForHumansFromCid(cid, roleToPlayerName, gameTag) {
  if (cid === "*cidUnknown*" || cid === null) {
    return {displayedName: "a card"};
  } else if (cid === "*cidUnknownByError*") {
    return {displayedName: "?a card that should have been known?"};
  } else if (cid === void 0) {
    return {
      displayedName: "?a card whose info is missing?"
    };
  }
  const parts = cid.split(gameTag === "mv" ? "#" : "-");
  if (parts.length === 1 || parts.length === 2) {
    const [base, n] = parts;
    const cardInfo = baseToCardInfo[base];
    if (!cardInfo) {
      console.error("No card info for cid", cid);
      return {displayedName: "?a card?"};
    }
    const {cardName, imageName} = cardInfo;
    const displayedName = n !== void 0 && n !== 0 ? `${cardName} #${n}` : `${cardName}`;
    return {displayedName, imageName};
  } else if (parts.length === 3) {
    const [role, fdLiteral, n] = parts;
    if (fdLiteral !== "fd") {
      console.error("Unrecognized three-part client cid", cid);
      return {displayedName: "?a card?"};
    }
    const playerName = roleToPlayerName[role];
    return {displayedName: `${playerName}'s facedown card #${n}`};
  } else {
    console.error("Unrecognized multipart client cid", cid);
    return {displayedName: "?a card?"};
  }
}
export function cardForHumansFromYourKnowledge(knowledge, roleToPlayerName) {
  if (knowledge === null) {
    return {displayedName: "a card"};
  } else if (knowledge === void 0) {
    return {
      displayedName: "?a card whose info is missing?"
    };
  }
  const {knowledgeType, cid} = knowledge;
  const [base, n] = cid.split("#");
  if (knowledgeType === "full") {
    const cardInfo = baseToCardInfo[base];
    if (!cardInfo) {
      console.error("No card info for cid", cid);
      return {displayedName: "?a card?"};
    }
    const {cardName, imageName} = cardInfo;
    const displayedName = `${cardName} #${n}`;
    return {displayedName, imageName, linkHref: cid};
  } else if (knowledgeType === "limited") {
    const role = base.substring(1);
    const playerName = roleToPlayerName[role];
    return {displayedName: `${playerName}'s facedown card #${n}`};
  }
}
export const orderLabelTemplates = {
  "play-replacing": "Play (replacing)",
  "play-decrease-hand-size": "Play (-1 hand size)",
  "discard-replacing": "Discard (replacing)",
  "discard-decrease-hand-size": "Discard (-1 hand size)",
  "burn-blood": "Burn %n blood",
  "add-blood": "Add %n blood",
  "decrease-bleed": "-1 bleed",
  "increase-bleed": "+1 bleed",
  "decrease-strength": "-1 strength",
  "increase-strength": "+1 strength",
  "decrease-votes": "-1 votes",
  "increase-votes": "+1 votes",
  "decrease-stealth": "-1 stealth",
  "increase-stealth": "+1 stealth",
  "decrease-intercept": "-1 intercept",
  "increase-intercept": "+1 intercept",
  "transfer-take": "Transfer: take %n blood",
  "transfer-put": "Transfer: put %n blood",
  "draw-increase-hand-size": "Draw (+1 hand size)",
  "search-library": "Search library",
  "library-to-hand-increase-hand-size": "Move to hand (+ 1 hand size)",
  "cancel-search-library": "Cancel searching",
  "transfer-recruit": "Transfer: recruit",
  recruit: "Recruit",
  "search-crypt": "Search crypt",
  "crypt-to-uncontrolled": "To uncontrolled",
  "cancel-search-crypt": "Cancel searching",
  "burn-pool": "Burn %n pool",
  "gain-pool": "Gain %n pool",
  "lose-vp": "Lose %n vp",
  "gain-vp": "Gain %n vp",
  "current-discard": "Discard",
  "current-to-support": "Put into the support zone",
  "current-to-ready": "Place in the ready region",
  "current-attach": "Attach to...",
  "gain-edge": "Gain the edge",
  "burn-edge": "Burn the edge",
  "get-ready": "Get ready",
  "unlock-your-cards": "Unlock all your cards",
  "discard-current-cards": "Discard current cards",
  "end-action": "End action",
  "end-block": "End block",
  "end-turn": "End turn",
  "indicate-no-reaction": "No reaction",
  "indicate-please-wait": "Please wait",
  "declare-acting-and-lock": "Declare acting (and lock)",
  "declare-blocking": "Declare blocking",
  "in-play-lock": "Lock",
  "in-play-unlock": "Unlock",
  "in-play-turn-facedown": "Turn facedown",
  "in-play-turn-faceup": "Turn faceup",
  "ready-to-torpor": "Move to torpor",
  "torpor-to-ready": "Move to the ready region",
  "in-play-burn": "Burn",
  "in-play-to-acting-player": `Acting player takes control`,
  "ash-heap-to-hand": "Return to hand (+1 hand size)",
  oust: "Oust",
  "*cancelAttaching*": "Cancel attaching",
  "*attachHere*": "Attach here",
  "attached-reattach": "Attach to another card"
};
export const mvOrderLabelTemplates = {
  mvGeneral: {
    playReplacing: "Play (replacing)",
    discardReplacing: "Discard (replacing)",
    playDecreaseHandSize: "Play (-1 hand size)",
    discardDecreaseHandSize: "Discard (-1 hand size)",
    burnBlood: "Burn 1 blood",
    addBlood: "Add 1 blood",
    transferTake: "2 transfers: take 1",
    transferPut: "Transfer: put 1",
    drawIncreaseHandSize: "Draw (+1 hand size)",
    searchLibrary: "Search library",
    transferRecruit: "4 transfers and 1 pool: recruit",
    recruit: "Recruit",
    searchCrypt: "Search crypt",
    burnPool: "Burn 1 pool",
    gainPool: "Gain 1 pool",
    burnEdge: "Burn the edge",
    gainEdge: "Gain the edge",
    loseVp: "Lose 1 vp",
    gainVp: "Gain 1 vp",
    currentToAshHeap: "Discard",
    currentToSupport: "Put into the support zone",
    currentToReady: "Place in the ready region",
    currentAttach: "Attach to",
    unlockYourCards: "Unlock your cards",
    discardCurrentCards: "Discard current cards",
    endAction: "End action",
    endTurn: "End turn",
    declareActingAndLock: "Declare acting (and lock)",
    declareBlocking: "Declare blocking",
    bringUncontrolledIntoPlay: "Bring into play",
    inPlayLock: "Lock",
    inPlayUnlock: "Unlock",
    inPlayTurnFacedown: "Turn facedown",
    inPlayTurnFaceup: "Turn faceup",
    readyToTorpor: "Move to torpor",
    torporToReady: "Move to the ready region",
    inPlayBurn: "Burn",
    inPlayToActivePlayer: "Give control to acting player",
    attachedReattach: "Attach to another card",
    ashHeapToHand: "Return to hand (+1 hand size)",
    getOusted: "Get ousted",
    indicatePleaseWait: "Please wait",
    indicateNoReaction: "No reaction"
  },
  mvSearchingLibrary: {
    libraryToHand: "Move to hand (+ 1 hand size)",
    stopSearchingLibrary: "Stop searching library"
  },
  mvSearchingCrypt: {
    cryptToUncontrolled: "Move to uncontrolled",
    stopSearchingCrypt: "Stop searching crypt"
  }
};
export const templates = {
  "t-endTurn": [
    "%r ends the turn (%n pool). %z",
    "You end the turn (%n pool). %z"
  ],
  "t-reportStartGame": "Game has started.  Seating: %a.",
  "t-reportStartTurn": [
    "%r starts their turn in round %n1 (%n2 pool).",
    "You start your turn in round %n1 (%n2 pool)."
  ],
  "t-discard-decrease-hand-size": [
    "%r discards %c1 (-1 hand size).",
    "You discard %c1 (-1 hand size)."
  ],
  "t-discard-replacing": [
    "%r discards %c1, drawing %c2.",
    "You discard %c1, drawing %c2."
  ],
  "t-play-decrease-hand-size": [
    "%r plays %c1 (-1 hand size).",
    "You play %c1 (-1 hand size)."
  ],
  "t-play-replacing": [
    "%r plays %c1, drawing %c2.",
    "You play %c1, drawing %c2."
  ],
  "t-transfer-put": [
    "%r transfers %n1 blood from the pool (now at %n2) onto %c (now %n3).",
    "You transfer %n1 blood from the pool (now at %n2) onto %c (now %n3)."
  ],
  "t-transfer-take": [
    "%r transfers %n1 blood from %c (now %n3) to the pool (now at %n2).",
    "You transfer %n1 blood from %c (now %n3) to the pool (now at %n2)."
  ],
  "t-draw-increase-hand-size": [
    "%r draws %c (+1 hand size).",
    "You draw %c (+1 hand size)."
  ],
  "t-search-library": [
    "%r searches their library.",
    "You search your library."
  ],
  "t-library-to-hand-increase-hand-size": [
    "%r moves %c to their hand (+1 hand size).",
    "You move %c to your hand (+1 hand size)."
  ],
  "t-cancel-search-library": [
    "%r stops searching their library.",
    "You stop searching your library."
  ],
  "t-transfer-recruit": [
    "%r spends 4 transfers and 1 pool (now %n) to recruit %c.",
    "You spend 4 transfers and 1 pool (now %n) to recruit %c."
  ],
  "t-recruit": ["%r recruits %c.", "You recruit %c."],
  "t-search-crypt": ["%r searches their crypt.", "You search your crypt."],
  "t-crypt-to-uncontrolled": [
    "%r moves %c to their uncontrolled region.",
    "You move %c to your uncontrolled region."
  ],
  "t-cancel-search-crypt": [
    "%r cancels searching their crypt.",
    "You cancel searching your crypt."
  ],
  "t-burn-pool": [
    "%r burns %n1 pool (now %n2).",
    "You burn %n1 pool (now %n2)."
  ],
  "t-gain-pool": [
    "%r gains %n1 pool (now %n2).",
    "You gain %n1 pool (now %n2)."
  ],
  "t-gain-vp": ["%r gains %n1 vp (now %n2).", "You gain %n1 vp (now %n2)."],
  "t-lose-vp": ["%r loses %n1 vp (now %n2).", "You lose %n1 vp (now %n2)."],
  "t-burn-blood": [
    "%r burns %n1 blood from %c (now %n2).",
    "You burn %n1 blood from %c (now %n2)."
  ],
  "t-add-blood": [
    "%r adds %n1 blood to %c (now %n2).",
    "You add %n1 blood to %c (now %n2)."
  ],
  "t-decrease-bleed": [
    "%r decreases bleed of %c by %n1 (now %n2).",
    "You decrease bleed of %c by %n1 (now %n2)."
  ],
  "t-increase-bleed": [
    "%r increases bleed of %c by %n1 (now %n2).",
    "You increase bleed of %c by %n1 (now %n2)."
  ],
  "t-decrease-strength": [
    "%r decreases strength of %c by %n1 (now %n2).",
    "You decrease strength of %c by %n1 (now %n2)."
  ],
  "t-increase-strength": [
    "%r increases strength of %c by %n1 (now %n2).",
    "You increase strength of %c by %n1 (now %n2)."
  ],
  "t-decrease-votes": [
    "%r decreases votes of %c by %n1 (now %n2).",
    "You decrease votes of %c by %n1 (now %n2)."
  ],
  "t-increase-votes": [
    "%r increases votes of %c by %n1 (now %n2).",
    "You increase votes of %c by %n1 (now %n2)."
  ],
  "t-decrease-stealth": [
    "%r decreases stealth of %c by %n1 (now %n2).",
    "You decrease stealth of %c by %n1 (now %n2)."
  ],
  "t-increase-stealth": [
    "%r increases stealth of %c by %n1 (now %n2).",
    "You increase stealth of %c by %n1 (now %n2)."
  ],
  "t-decrease-intercept": [
    "%r decreases intercept of %c by %n1 (now %n2).",
    "You decrease intercept of %c by %n1 (now %n2)."
  ],
  "t-increase-intercept": [
    "%r increases intercept of %c by %n1 (now %n2).",
    "You increase intercept of %c by %n1 (now %n2)."
  ],
  "t-current-discard": [
    "%r discards the played card %c1.",
    "You discard the played card %c1."
  ],
  "t-current-to-support": [
    "%r puts %c1 into the support zone.",
    "You put %c1 into the support zone."
  ],
  "t-current-to-ready": [
    "%r places %c1 in the ready region.",
    "You place %c1 in the ready region."
  ],
  "t-gain-edge": ["%r gains the edge.", "You gain the edge."],
  "t-burn-edge": ["%r burns the edge.", "You burn the edge."],
  "t-get-ready": ["%r brings %c into play.", "You bring %c into play."],
  "t-unlock-your-cards": ["%r unlocks their cards.", "You unlock your cards."],
  "t-discard-current-cards": [
    "%r discards current cards.",
    "You discard current cards."
  ],
  "t-indicate-no-reaction": [
    "%r indicates no reaction.",
    "You indicate no reaction."
  ],
  "t-indicate-please-wait": [
    "%r asks to please wait.",
    "You ask to please wait."
  ],
  "t-declare-acting-and-lock": [
    "%r declares %c to be acting.",
    "You declare %c to be acting."
  ],
  "t-declare-blocking": [
    "%r declares %c to be blocking.",
    "You declare %c to be blocking."
  ],
  "t-in-play-lock": ["%r locks %c.", "You lock %c."],
  "t-in-play-unlock": ["%r unlocks %c.", "You unlock %c."],
  "t-in-play-turn-facedown": ["%r turns %c facedown.", "You turn %c facedown."],
  "t-in-play-turn-faceup": ["%r turns %c faceup.", "You turn %c faceup."],
  "t-ready-to-torpor": ["%r moves %c to torpor.", "You move %c to torpor."],
  "t-torpor-to-ready": [
    "%r moves %c from torpor to the ready region.",
    "You move %c from torpor to the ready region."
  ],
  "t-in-play-burn": ["%r burns %c.", "You burn %c."],
  "t-in-play-to-acting-player": [
    "%r gives control over %c to %r2.",
    "You give control over %c to %r2."
  ],
  "t-current-attach": ["%r attaches %c to %c2.", "You attach %c to %c2."],
  "t-ash-heap-to-hand": [
    "%r returns %c from the ash heap to hand (+1 hand size).",
    "You return %c from the ash heap to hand (+1 hand size)."
  ],
  "t-oust": ["%r is ousted.", "You are ousted."],
  "t-attached-reattach": [
    "%r reattaches %c to %c2.",
    "You reattach %c to %c2."
  ],
  "t-end-action": ["%r ends the action.", "You end the action."],
  "t-end-block": ["%r ends the block.", "You end the block."]
};
export function getZoneNameForHumans(slug) {
  const names = {
    ashHeap: "Ash heap",
    crypt: "Crypt",
    library: "Library"
  };
  const result = names[slug];
  if (!result) {
    console.error("Cannot get name for zone slug", slug);
    return null;
  }
  return result;
}
