import React from "../_snowpack/pkg/react.js";
import {BrowserRouter as Router, Switch, Route} from "../_snowpack/pkg/react-router-dom.js";
import {Home} from "./pages/Home.js";
import {GameLoader} from "./components/GameLoader.js";
export function App() {
  return /* @__PURE__ */ React.createElement("div", {
    className: "App"
  }, /* @__PURE__ */ React.createElement(Router, null, /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, {
    path: "/",
    exact: true,
    component: Home
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/play/:id",
    component: GameLoader
  }))));
}
export default App;
