import produce from "../../../pkg/immer.js";
import {
  indentedList,
  buildZoneName,
  getZoneName,
  getZoneYourAccess,
  getTranslatedCidFromKnowledge
} from "./utils.js";
import {
  getZoneAccessRep,
  getZoneRepModifiers,
  getCardLocationRep,
  getCardRepModifiers,
  getSettersRep,
  getChangersRep
} from "./rep.js";
import {cardToVCard, historyEntryToVHistoryEntry} from "./view.js";
export const dc = {
  empty() {
    return {
      deltaType: "empty",
      toView(_pov) {
        return {};
      }
    };
  },
  playerAdded(playerName, role) {
    return {
      deltaType: "playerAdded",
      playerName,
      role,
      toView(_pov) {
        return {
          playerName,
          role
        };
      }
    };
  },
  zoneCreated(zoneKind, owner, slug, access) {
    return {
      deltaType: "zoneCreated",
      zoneKind,
      owner,
      slug,
      access,
      toView(pov) {
        const yourAccess = getZoneYourAccess(access, pov);
        return {
          zoneKind,
          owner,
          slug,
          access,
          yourAccess
        };
      }
    };
  },
  seq(childDeltas) {
    return {
      deltaType: "seq",
      childDeltas,
      toView(pov) {
        const {childDeltas: childDeltas2} = this;
        const vChildDeltas = childDeltas2.map((childDelta) => deltaToVDelta(childDelta, pov));
        return {
          childDeltas: vChildDeltas
        };
      }
    };
  },
  cardCreated(card, location) {
    return {
      deltaType: "cardCreated",
      card,
      location,
      toView(pov) {
        return {
          location,
          card: cardToVCard(card, pov)
        };
      }
    };
  },
  cardChangedFacing(card, facing, location) {
    return {
      deltaType: "cardChangedFacing",
      card,
      facing,
      location,
      toView(pov) {
        return {
          card: cardToVCard(card, pov),
          facing,
          location
        };
      }
    };
  },
  cardMoved(card, sourceLocation, targetLocation) {
    return {
      deltaType: "cardMoved",
      card,
      sourceLocation,
      targetLocation,
      toView(pov) {
        return {
          card: cardToVCard(card, pov),
          sourceLocation,
          targetLocation
        };
      }
    };
  },
  cardChangedRotation(card, rotation, location) {
    return {
      deltaType: "cardChangedRotation",
      card,
      rotation,
      location,
      toView(pov) {
        return {
          card: cardToVCard(card, pov),
          rotation,
          location
        };
      }
    };
  },
  cardVarsSet(card, location, setters) {
    return {
      deltaType: "cardVarsSet",
      card,
      location,
      setters,
      toView(pov) {
        return {
          card: cardToVCard(card, pov),
          location,
          setters
        };
      }
    };
  },
  cardVarsChanged(card, location, changers) {
    return {
      deltaType: "cardVarsChanged",
      card,
      location,
      changers,
      toView(pov) {
        return {
          card: cardToVCard(card, pov),
          location,
          changers
        };
      }
    };
  },
  stateVarsSet(setters) {
    return {
      deltaType: "stateVarsSet",
      setters,
      toView(pov) {
        return {
          setters
        };
      }
    };
  },
  stateVarsChanged(changers, newValues) {
    return {
      deltaType: "stateVarsChanged",
      changers,
      newValues,
      toView(pov) {
        return {
          changers,
          newValues
        };
      }
    };
  },
  zoneAccessSet(zoneName, zoneKind, access, cards, oldAccess) {
    return {
      deltaType: "zoneAccessSet",
      zoneName,
      zoneKind,
      access,
      cards,
      oldAccess,
      toView(pov) {
        const yourAccess = getZoneYourAccess(access, pov);
        const oldYourAccess = getZoneYourAccess(oldAccess, pov);
        const includeCids = oldYourAccess !== "accessible" && yourAccess === "accessible";
        const shouldTranslateCards = zoneKind === "region" || yourAccess === "accessible";
        return {
          zoneName,
          access,
          cards: shouldTranslateCards ? cards.map((card) => cardToVCard(card, pov)) : null,
          oldAccess,
          yourAccess,
          includeCids
        };
      }
    };
  },
  zoneRearranged(zone) {
    return {
      deltaType: "zoneRearranged",
      zone,
      toView(pov) {
        const {zoneKind, access, cards} = zone;
        const zoneName = getZoneName(zone);
        const yourAccess = getZoneYourAccess(access, pov);
        const shouldTranslateCards = zoneKind === "region" || yourAccess === "accessible";
        return {
          zoneName,
          cards: shouldTranslateCards ? cards.map((card) => cardToVCard(card, pov)) : null
        };
      }
    };
  },
  historyEntryAdded(historyEntry) {
    return {
      deltaType: "historyEntryAdded",
      historyEntry,
      toView(pov) {
        return {
          historyEntry: historyEntryToVHistoryEntry(historyEntry, pov)
        };
      }
    };
  }
};
export function deltaToVDelta(delta, pov) {
  return {
    deltaType: delta.deltaType,
    ...delta.toView(pov)
  };
}
export function deltaToRep(delta, pov) {
  const vDelta = deltaToVDelta(delta, pov);
  return vDeltaToRep(vDelta);
}
export function addVCardToLocation(vs, card, location) {
  const {zoneName, index, attachmentIndex} = location;
  const zone = vs.zones[zoneName];
  const zoneHasCards = zone.cards !== null;
  const newZone = produce(zone, (draftZone) => {
    if (attachmentIndex !== void 0) {
      draftZone.cards[index].attachedCards.splice(attachmentIndex, 0, card);
    } else {
      draftZone.nCards += 1;
      if (zoneHasCards) {
        draftZone.cards.splice(index, 0, card);
        draftZone.topCardBack = draftZone.cards[0].cardBack;
      }
    }
  });
  return produce(vs, (draft) => {
    draft.zones[zoneName] = newZone;
  });
}
export function removeVCardAtLocation(vs, location) {
  const {zoneName, index, attachmentIndex} = location;
  const zone = vs.zones[zoneName];
  const zoneHasCards = zone.cards !== null;
  const newZone = produce(zone, (draftZone) => {
    if (attachmentIndex !== void 0) {
      draftZone.cards[index].attachedCards.splice(attachmentIndex, 1);
    } else {
      if (zoneHasCards) {
        draftZone.cards.splice(index, 1);
      }
      draftZone.nCards -= 1;
      draftZone.topCardBack = draftZone.nCards === 0 ? null : zoneHasCards ? draftZone.cards[0].cardBack : draftZone.topCardBack;
    }
  });
  return produce(vs, (draft) => {
    draft.zones[zoneName] = newZone;
  });
}
export function replaceVCardAtLocation(vs, card, location) {
  const {zoneName, index, attachmentIndex} = location;
  const zone = vs.zones[zoneName];
  const zoneHasCards = zone.cards !== null;
  const newZone = produce(zone, (draftZone) => {
    if (zoneHasCards) {
      if (attachmentIndex !== void 0) {
        draftZone.cards[index].attachedCards.splice(attachmentIndex, 1, card);
      } else {
        draftZone.cards.splice(index, 1, card);
      }
    }
  });
  return produce(vs, (draft) => {
    draft.zones[zoneName] = newZone;
  });
}
export function applyVDelta(vs, delta) {
  const {deltaType} = delta;
  switch (deltaType) {
    case "empty": {
      return vs;
    }
    case "playerAdded": {
      const {playerName, role} = delta;
      return produce(vs, (draft) => {
        draft.roleToPlayerName[role] = playerName;
        draft.roles.push(role);
      });
    }
    case "zoneCreated": {
      const {zoneKind, owner, slug, access, yourAccess} = delta;
      const zone = {
        zoneKind,
        owner,
        slug,
        access,
        yourAccess,
        cards: [],
        nCards: 0,
        topCardBack: null
      };
      const zoneName = buildZoneName(owner, slug);
      return produce(vs, (draft) => {
        draft.zones[zoneName] = zone;
      });
    }
    case "seq": {
      const {childDeltas} = delta;
      let current = vs;
      childDeltas.forEach((delta2) => {
        current = applyVDelta(current, delta2);
      });
      return current;
    }
    case "cardCreated": {
      const {card, location} = delta;
      return addVCardToLocation(vs, card, location);
    }
    case "cardChangedFacing": {
      const {card, facing, location} = delta;
      return replaceVCardAtLocation(vs, card, location);
    }
    case "cardMoved": {
      const {card, sourceLocation, targetLocation} = delta;
      let current = removeVCardAtLocation(vs, sourceLocation);
      current = addVCardToLocation(current, card, targetLocation);
      return current;
    }
    case "cardChangedRotation": {
      const {card, location} = delta;
      return replaceVCardAtLocation(vs, card, location);
    }
    case "cardVarsSet": {
      const {card, location} = delta;
      return replaceVCardAtLocation(vs, card, location);
    }
    case "cardVarsChanged": {
      const {card, location} = delta;
      return replaceVCardAtLocation(vs, card, location);
    }
    case "stateVarsSet": {
      const {setters} = delta;
      return produce(vs, (draft) => {
        Object.entries(setters).forEach(([k, v]) => {
          draft.vars[k] = v;
        });
      });
    }
    case "stateVarsChanged": {
      const {newValues} = delta;
      const newVars = produce(vs.vars, (draftVars) => {
        Object.entries(newValues).forEach(([k, v]) => {
          draftVars[k] = v;
        });
      });
      return produce(vs, (draft) => {
        draft.vars = newVars;
      });
    }
    case "zoneAccessSet": {
      const {
        zoneName,
        oldAccess,
        access,
        cards,
        includeCids,
        yourAccess
      } = delta;
      return produce(vs, (draft) => {
        draft.zones[zoneName].access = access;
        draft.zones[zoneName].yourAccess = yourAccess;
        draft.zones[zoneName].cards = cards;
      });
    }
    case "zoneRearranged": {
      const {zoneName, cards} = delta;
      return produce(vs, (draft) => {
        draft.zones[zoneName].cards = cards;
      });
    }
    case "historyEntryAdded": {
      const {historyEntry} = delta;
      return produce(vs, (draft) => {
        draft.historyEntries.push(historyEntry);
      });
    }
    default: {
      console.warn(`unrecognized deltaType for applyVDelta:`, delta);
      return vs;
    }
  }
}
export function getVCardTranslatedCid(card) {
  return getTranslatedCidFromKnowledge(card.yourKnowledge);
}
export function vDeltaToRep(delta, level = 0) {
  if (!delta) {
    return ":none";
  }
  const {deltaType} = delta;
  function fromParts(...parts) {
    const head = `:${deltaType}`;
    return [head, ...parts].join(" ");
  }
  switch (deltaType) {
    case "empty": {
      return fromParts();
    }
    case "playerAdded": {
      const {playerName, role} = delta;
      return fromParts(playerName, role);
    }
    case "zoneCreated": {
      const {zoneKind, owner, slug, access, yourAccess} = delta;
      const zoneName = buildZoneName(owner, slug);
      const accessRep = getZoneAccessRep(access);
      const modifiers = getZoneRepModifiers(zoneKind, yourAccess);
      return fromParts(zoneName, modifiers, accessRep);
    }
    case "seq": {
      const {childDeltas} = delta;
      const childReps = childDeltas.map((childDelta) => vDeltaToRep(childDelta, level + 1));
      const beginning = `:${deltaType}`;
      return indentedList(beginning, childReps, level);
    }
    case "cardCreated": {
      const {card, location} = delta;
      const translatedCid = getVCardTranslatedCid(card);
      const locationRep = getCardLocationRep(location);
      const parts = [locationRep, translatedCid];
      if (card.tag === "cardInRegion") {
        const {facing, rotation} = card;
        const modifiers = getCardRepModifiers(facing, rotation);
        parts.push(modifiers);
      }
      return fromParts(...parts);
    }
    case "cardChangedFacing": {
      const {card, facing, location} = delta;
      const translatedCid = getVCardTranslatedCid(card);
      const locationRep = getCardLocationRep(location);
      return fromParts(locationRep, translatedCid, facing);
    }
    case "cardMoved": {
      const {card, sourceLocation, targetLocation} = delta;
      const parts = [
        getCardLocationRep(sourceLocation),
        getCardLocationRep(targetLocation),
        getVCardTranslatedCid(card)
      ];
      if (card.tag === "cardInRegion") {
        const {facing, rotation} = card;
        parts.push(getCardRepModifiers(facing, rotation));
      }
      return fromParts(...parts);
    }
    case "cardChangedRotation": {
      const {card, rotation, location} = delta;
      const translatedCid = getVCardTranslatedCid(card);
      const locationRep = getCardLocationRep(location);
      return fromParts(locationRep, translatedCid, rotation);
    }
    case "cardVarsSet": {
      const {card, location, setters} = delta;
      const translatedCid = getVCardTranslatedCid(card);
      const locationRep = getCardLocationRep(location);
      const settersRep = getSettersRep(setters);
      return fromParts(locationRep, translatedCid, settersRep);
    }
    case "cardVarsChanged": {
      const {card, location, changers} = delta;
      const translatedCid = getVCardTranslatedCid(card);
      const locationRep = getCardLocationRep(location);
      const changersRep = getChangersRep(changers, card.vars);
      return fromParts(locationRep, translatedCid, changersRep);
    }
    case "stateVarsSet": {
      const {setters} = delta;
      const settersRep = getSettersRep(setters);
      return fromParts(settersRep);
    }
    case "stateVarsChanged": {
      const {changers, newValues} = delta;
      const changersRep = getChangersRep(changers, newValues);
      return fromParts(changersRep);
    }
    case "zoneAccessSet": {
      const {
        zoneName,
        oldAccess,
        access,
        cards,
        includeCids,
        yourAccess
      } = delta;
      const beginning = `:${deltaType}`;
      const accessRep = getZoneAccessRep(access);
      const parts = [zoneName, accessRep, yourAccess];
      const header = [beginning, ...parts].join(" ");
      if (includeCids) {
        const cids = cards.map((card) => getVCardTranslatedCid(card));
        return indentedList(header, cids, level);
      } else {
        return header;
      }
    }
    case "zoneRearranged": {
      const {zoneName, cards} = delta;
      const beginning = `:${deltaType}`;
      const header = [beginning, zoneName].join(" ");
      if (cards !== null) {
        const cids = cards.map((card) => getVCardTranslatedCid(card));
        return indentedList(header, cids, level);
      } else {
        return header;
      }
    }
    default: {
      console.warn("unrecognized deltaType for vDeltaToRep", delta);
      return `:${deltaType} TODO`;
    }
  }
}
