import React, {useEffect} from "../../_snowpack/pkg/react.js";
import {useSelector} from "../../_snowpack/pkg/react-redux.js";
import {getGameTagFromId} from "../../_snowpack/link/engine/src/main.js";
import {useSendMessage} from "../hooks/useSendMessage.js";
import {selectGameId} from "../slices/common/commonSlice.js";
import {MvGameUi} from "./MvGameUi.js";
import {Loading} from "./Loading.js";
export function GameLoader(props) {
  const gameId = props.match.params.id;
  const currentGameId = useSelector(selectGameId);
  const sendMessage = useSendMessage();
  useEffect(() => {
    if (currentGameId !== gameId) {
      sendMessage({tag: "requestGameId", gameId});
    }
  }, [currentGameId, gameId, sendMessage]);
  if (currentGameId === gameId) {
    const gameTag = getGameTagFromId(gameId);
    if (gameTag === "mv") {
      return /* @__PURE__ */ React.createElement(MvGameUi, null);
    } else {
      return /* @__PURE__ */ React.createElement(React.Fragment, null, "error $", gameTag);
    }
  }
  return /* @__PURE__ */ React.createElement(Loading, {
    where: "GameLoader"
  });
}
