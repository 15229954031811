import React from "../../_snowpack/pkg/react.js";
import {Tab} from "./Tab.js";
import {selectRoles, selectRoleToPlayerName} from "../selectors.js";
import {useSelector} from "../../_snowpack/pkg/react-redux.js";
export function Navbar(props) {
  const {tab, setTab} = props;
  const roles = useSelector(selectRoles);
  const roleToPlayerName = useSelector(selectRoleToPlayerName);
  return /* @__PURE__ */ React.createElement("nav", {
    className: "Navbar"
  }, /* @__PURE__ */ React.createElement("ul", null, /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement("b", null, "Backways")), /* @__PURE__ */ React.createElement("li", null, "switch to pov of:"), roles && roles.map((value) => {
    return /* @__PURE__ */ React.createElement(Tab, {
      key: value,
      value,
      text: roleToPlayerName[value],
      tab,
      setTab
    });
  })));
}
