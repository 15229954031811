import React from "../../_snowpack/pkg/react.js";
import {connect} from "../../_snowpack/pkg/react-redux.js";
import {CardLink} from "./CardLink.js";
import {
  templates,
  varNameForHumans,
  cardForHumansFromYourKnowledge
} from "../translations.js";
import {selectRoleToPlayerName} from "../selectors.js";
export const LogLine = function() {
  function LogLine2(props) {
    const {clientLogLine, roleToPlayerName, gameTag, yourRole} = props;
    let {template, templateCode, args} = clientLogLine;
    if (templateCode) {
      const templateOrTemplatePair = templates[templateCode];
      if (!templateOrTemplatePair) {
        console.warn("Unrecognized template code", templateCode, clientLogLine);
        const s = [templateCode, ...Object.values(args)].join(" :: ");
        template = `?${s}?`;
      } else if (Array.isArray(templateOrTemplatePair)) {
        const templatePair = templateOrTemplatePair;
        const isYou = args["r"] === yourRole;
        template = templatePair[isYou ? 1 : 0];
      } else {
        template = templateOrTemplatePair;
      }
    }
    const re = /%(([a-z]+)(\d+)?)/g;
    const parts = [];
    function addLiteralPart(part) {
      if (!(part.length > 0)) {
        return;
      }
      parts.push(part);
    }
    function addComponentPart(part) {
      parts.push(/* @__PURE__ */ React.createElement(React.Fragment, {
        key: parts.length
      }, part));
    }
    function getHoleReplacementComponent(holeName, holeType) {
      const holeValue = args[holeName];
      switch (holeType) {
        case "v":
          return /* @__PURE__ */ React.createElement("b", null, varNameForHumans(holeValue, roleToPlayerName));
        case "r": {
          if (holeValue === yourRole) {
            return /* @__PURE__ */ React.createElement(React.Fragment, null, "you");
          } else {
            return /* @__PURE__ */ React.createElement("b", null, roleToPlayerName[holeValue]);
          }
        }
        case "n": {
          return /* @__PURE__ */ React.createElement("b", null, holeValue);
        }
        case "c": {
          const {displayedName, imageName, linkHref} = cardForHumansFromYourKnowledge(holeValue, roleToPlayerName);
          if (!imageName) {
            return displayedName;
          }
          return /* @__PURE__ */ React.createElement(CardLink, {
            displayedName,
            cid: linkHref,
            imageName,
            gameTag
          });
        }
        case "a": {
          const n = holeValue.length;
          return /* @__PURE__ */ React.createElement(React.Fragment, null, holeValue.map((part, index) => /* @__PURE__ */ React.createElement(React.Fragment, {
            key: part
          }, /* @__PURE__ */ React.createElement("b", null, part), index < n - 1 ? ", " : "")));
        }
        case "z": {
          return /* @__PURE__ */ React.createElement("hr", null);
        }
        default: {
          console.warn("Unrecognized holeType", holeType);
          return /* @__PURE__ */ React.createElement("b", null, holeValue);
        }
      }
    }
    let currentIndex = 0;
    let match;
    while ((match = re.exec(template)) !== null) {
      const {index} = match;
      addLiteralPart(template.substring(currentIndex, index));
      addComponentPart(getHoleReplacementComponent(match[1], match[2]));
      currentIndex = re.lastIndex;
    }
    addLiteralPart(template.substring(currentIndex));
    return /* @__PURE__ */ React.createElement("li", null, parts);
  }
  function mapStateToProps(state, ownProps) {
    let roleToPlayerName = selectRoleToPlayerName(state);
    return {
      roleToPlayerName
    };
  }
  return connect(mapStateToProps)(LogLine2);
}();
