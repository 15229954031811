export const baseToCardInfo = {
  'achugr': {
    cardName: 'Academic Hunting Ground',
    back: 'library',
    imageName: 'fifth/academichuntingground',
  },
  'anatro': {
    cardName: 'Anarch Troublemaker',
    back: 'library',
    imageName: 'fifth/anarchtroublemaker',
  },
  'ancemp': {
    cardName: 'Ancilla Empowerment',
    back: 'library',
    imageName: 'fifth/ancillaempowerment',
  },
  'arclib': {
    cardName: 'Arcane Library',
    back: 'library',
    imageName: 'fifth/arcanelibrary',
  },
  'artmus': {
    cardName: 'Art Museum',
    back: 'library',
    imageName: 'fifth/artmuseum',
  },
  'ashugr': {
    cardName: 'Asylum Hunting Ground',
    back: 'library',
    imageName: 'fifth/asylumhuntingground',
  },
  'thebar': {
    cardName: 'The Barrens',
    back: 'library',
    imageName: 'fifth/barrensthe',
  },
  'bewora': {
    cardName: 'Bewitching Oration',
    back: 'library',
    imageName: 'fifth/bewitchingoration',
  },
  'blodol': {
    cardName: 'Blood Doll',
    back: 'library',
    imageName: 'fifth/blooddoll',
  },
  'bondin': {
    cardName: 'Bonding',
    back: 'library',
    imageName: 'fifth/bonding',
  },
  'catgui': {
    cardName: 'Cat’s Guidance',
    back: 'library',
    imageName: 'fifth/catsguidance',
  },
  'chofta': {
    cardName: 'Change of Target',
    back: 'library',
    imageName: 'fifth/changeoftarget',
  },
  'chantr': {
    cardName: 'Chantry',
    back: 'library',
    imageName: 'fifth/chantry',
  },
  'clthga': {
    cardName: 'Cloak the Gathering',
    back: 'library',
    imageName: 'fifth/cloakthegathering',
  },
  'condit': {
    cardName: 'Conditioning',
    back: 'library',
    imageName: 'fifth/conditioning',
  },
  'conboo': {
    cardName: 'Consanguineous Boon',
    back: 'library',
    imageName: 'fifth/consanguineousboon',
  },
  'deflec': {
    cardName: 'Deflection',
    back: 'library',
    imageName: 'fifth/deflection',
  },
  'eldlib': {
    cardName: 'Elder Library',
    back: 'library',
    imageName: 'fifth/elderlibrary',
  },
  'enckin': {
    cardName: 'Enchant Kindred',
    back: 'library',
    imageName: 'fifth/enchantkindred',
  },
  'facnig': {
    cardName: 'Faceless Night',
    back: 'library',
    imageName: 'fifth/facelessnight',
  },
  'fame': {
    cardName: 'Fame',
    back: 'library',
    imageName: 'fifth/fame',
  },
  'fredri': {
    cardName: 'Freak Drive',
    back: 'library',
    imageName: 'fifth/freakdrive',
  },
  'gothun': {
    cardName: 'Govern the Unaligned',
    back: 'library',
    imageName: 'fifth/governtheunaligned',
  },
  'immgra': {
    cardName: 'Immortal Grapple',
    back: 'library',
    imageName: 'fifth/immortalgrapple',
  },
  'infhig': {
    cardName: 'Information Highway',
    back: 'library',
    imageName: 'fifth/informationhighway',
  },
  'kireco': {
    cardName: 'Kine Resources Contested',
    back: 'library',
    imageName: 'fifth/kineresourcescontested',
  },
  'thelab': {
    cardName: 'The Labyrinth',
    back: 'library',
    imageName: 'fifth/labyrinththe',
  },
  'loincr': {
    cardName: 'Lost in Crowds',
    back: 'library',
    imageName: 'fifth/lostincrowds',
  },
  'mots': {
    cardName: 'Magic of the Smith',
    back: 'library',
    imageName: 'fifth/magicofthesmith',
  },
  'majest': {
    cardName: 'Majesty',
    back: 'library',
    imageName: 'fifth/majesty',
  },
  'misdir': {
    cardName: 'Misdirection',
    back: 'library',
    imageName: 'fifth/misdirection',
  },
  'muofcr': {
    cardName: 'Murder of Crows',
    back: 'library',
    imageName: 'fifth/murderofcrows',
  },
  'parshi': {
    cardName: 'Parity Shift',
    back: 'library',
    imageName: 'fifth/parityshift',
  },
  'ravspy': {
    cardName: 'Raven Spy',
    back: 'library',
    imageName: 'fifth/ravenspy',
  },
  'setrdo': {
    cardName: 'Second Tradition: Domain',
    back: 'library',
    imageName: 'fifth/secondtraditiondomain',
  },
  'slhugr': {
    cardName: 'Slum Hunting Ground',
    back: 'library',
    imageName: 'fifth/slumhuntingground',
  },
  'sjta': {
    cardName: 'Smiling Jack, the Anarch',
    back: 'library',
    imageName: 'fifth/smilingjacktheanarch',
  },
  'sohugr': {
    cardName: 'Society Hunting Ground',
    back: 'library',
    imageName: 'fifth/societyhuntingground',
  },
  'spobik': {
    cardName: 'Sport Bike',
    back: 'library',
    imageName: 'fifth/sportbike',
  },
  'spymis': {
    cardName: 'Spying Mission',
    back: 'library',
    imageName: 'fifth/spyingmission',
  },
  'taofvi': {
    cardName: 'Taste of Vitae',
    back: 'library',
    imageName: 'fifth/tasteofvitae',
  },
  'telmis': {
    cardName: 'Telepathic Misdirection',
    back: 'library',
    imageName: 'fifth/telepathicmisdirection',
  },
  'thofvi': {
    cardName: 'Theft of Vitae',
    back: 'library',
    imageName: 'fifth/theftofvitae',
  },
  'torjus': {
    cardName: 'Toreador Justicar',
    back: 'library',
    imageName: 'fifth/toreadorjusticar',
  },
  'undstr': {
    cardName: 'Underbridge Stray',
    back: 'library',
    imageName: 'fifth/underbridgestray',
  },
  'uphugr': {
    cardName: 'Uptown Hunting Ground',
    back: 'library',
    imageName: 'fifth/uptownhuntingground',
  },
  'venhea': {
    cardName: 'Ventrue Headquarters',
    back: 'library',
    imageName: 'fifth/ventrueheadquarters',
  },
  'votcap': {
    cardName: 'Voter Captivation',
    back: 'library',
    imageName: 'fifth/votercaptivation',
  },
  'wwef': {
    cardName: 'Wake with Evening’s Freshness',
    back: 'library',
    imageName: 'fifth/wakewitheveningsfreshness',
  },
  'alesil': {
    cardName: 'Alexander Silverson',
    back: 'crypt',
    imageName: 'fifth/alexandersilverson',
  },
  'andliu': {
    cardName: 'Andi Liu',
    back: 'crypt',
    imageName: 'fifth/andiliu',
  },
  'ashley': {
    cardName: 'Ashley',
    back: 'crypt',
    imageName: 'fifth/ashley',
  },
  'colett': {
    cardName: 'Colette',
    back: 'crypt',
    imageName: 'fifth/colette',
  },
  'donkow': {
    cardName: 'Donny Kowalczyk',
    back: 'crypt',
    imageName: 'fifth/donnykowalczyk',
  },
  'drstno': {
    cardName: 'Dr. Stephen Norton',
    back: 'crypt',
    imageName: 'fifth/drstephennorton',
  },
  'gelfot': {
    cardName: 'Gelasia Fotiou',
    back: 'crypt',
    imageName: 'fifth/gelasiafotiou',
  },
  'meagha': {
    cardName: 'Meaghan',
    back: 'crypt',
    imageName: 'fifth/meaghan',
  },
  'sully': {
    cardName: 'Sully',
    back: 'crypt',
    imageName: 'fifth/sully',
  },
  'dots': {
    cardName: 'Dreams of the Sphinx',
    back: 'library',
    imageName: 'fifth/dreamsofthesphinx',
  },
  'eyofar': {
    cardName: 'Eyes of Argus',
    back: 'library',
    imageName: 'fifth/eyesofargus',
  },
  'fordes': {
    cardName: 'Foreshadowing Destruction',
    back: 'library',
    imageName: 'fifth/foreshadowingdestruction',
  },
  'litc': {
    cardName: 'Life in the City',
    back: 'library',
    imageName: 'fifth/lifeinthecity',
  },
  'otqv': {
    cardName: 'On the Qui Vive',
    back: 'library',
    imageName: 'fifth/onthequivive',
  },
  'revela': {
    cardName: 'Revelations',
    back: 'library',
    imageName: 'fifth/revelations',
  },
  'sbtn': {
    cardName: 'Swallowed by the Night',
    back: 'library',
    imageName: 'fifth/swallowedbythenight',
  },
  'widvie': {
    cardName: 'Wider View',
    back: 'library',
    imageName: 'fifth/widerview',
  },
  'aunlin': {
    cardName: 'Aunt Linda',
    back: 'crypt',
    imageName: 'fifth/auntlinda',
  },
  'baixin': {
    cardName: 'Baixinho',
    back: 'crypt',
    imageName: 'fifth/baixinho',
  },
  'belind': {
    cardName: 'Belinde',
    back: 'crypt',
    imageName: 'fifth/belinde',
  },
  'thedow': {
    cardName: 'The Dowager',
    back: 'crypt',
    imageName: 'fifth/dowagerthe',
  },
  'horrad': {
    cardName: 'Horace Radcliffe',
    back: 'crypt',
    imageName: 'fifth/horaceradcliffe',
  },
  'larmor': {
    cardName: 'Larissa Moreira',
    back: 'crypt',
    imageName: 'fifth/larissamoreira',
  },
  'lenbur': {
    cardName: 'Lenny Burkhead',
    back: 'crypt',
    imageName: 'fifth/lennyburkhead',
  },
  'ryan': {
    cardName: 'Ryan',
    back: 'crypt',
    imageName: 'fifth/ryan',
  },
  'waunek': {
    cardName: 'Wauneka',
    back: 'crypt',
    imageName: 'fifth/wauneka',
  },
  'guaang': {
    cardName: 'Guardian Angel',
    back: 'library',
    imageName: 'fifth/guardianangel',
  },
  'havunc': {
    cardName: 'Haven Uncovered',
    back: 'library',
    imageName: 'fifth/havenuncovered',
  },
  'rebel': {
    cardName: 'Rebel',
    back: 'library',
    imageName: 'fifth/rebel',
  },
  'warsta': {
    cardName: 'Warsaw Station',
    back: 'library',
    imageName: 'fifth/warsawstation',
  },
  'vessel': {
    cardName: 'Vessel',
    back: 'library',
    imageName: 'fifth/vessel',
  },
  'cresab': {
    cardName: 'Creeping Sabotage',
    back: 'library',
    imageName: 'fifth/creepingsabotage',
  },
  'deeson': {
    cardName: 'Deep Song',
    back: 'library',
    imageName: 'fifth/deepsong',
  },
  'prestr': {
    cardName: 'Preternatural Strength',
    back: 'library',
    imageName: 'fifth/preternaturalstrength',
  },
  'carcro': {
    cardName: 'Carrion Crows',
    back: 'library',
    imageName: 'fifth/carrioncrows',
  },
  'roundh': {
    cardName: 'Roundhouse',
    back: 'library',
    imageName: 'fifth/roundhouse',
  },
  'guadog': {
    cardName: 'Guard Dogs',
    back: 'library',
    imageName: 'fifth/guarddogs',
  },
  'insrea': {
    cardName: 'Instinctive Reaction',
    back: 'library',
    imageName: 'fifth/instinctivereaction',
  },
  'prodis': {
    cardName: 'Protected District',
    back: 'library',
    imageName: 'fifth/protecteddistrict',
  },
  'thewar': {
    cardName: 'The Warrens',
    back: 'library',
    imageName: 'fifth/warrensthe',
  },
  'niksic': {
    cardName: 'Nik Sicko',
    back: 'crypt',
    imageName: 'fifth/niksicko',
  },
  'minseo': {
    cardName: 'Min-Seo',
    back: 'crypt',
    imageName: 'fifth/minseo',
  },
  'masfal': {
    cardName: 'Massimo Falconi',
    back: 'crypt',
    imageName: 'fifth/massimofalconi',
  },
  'brestr': {
    cardName: 'Bret Stryker',
    back: 'crypt',
    imageName: 'fifth/bretstryker',
  },
  'tamosz': {
    cardName: 'Tamoszius',
    back: 'crypt',
    imageName: 'fifth/tamoszius',
  },
  'katgle': {
    cardName: 'Kathy Glens',
    back: 'crypt',
    imageName: 'fifth/kathyglens',
  },
  'mkhokh': {
    cardName: 'Mkhokheli',
    back: 'crypt',
    imageName: 'fifth/mkhokheli',
  },
  'flagon': {
    cardName: 'Flávio Gonçalves',
    back: 'crypt',
    imageName: 'fifth/flaviogoncalves',
  },
  'catveg': {
    cardName: 'Catalina Vega',
    back: 'crypt',
    imageName: 'fifth/catalinavega',
  },
  'crecas': {
    cardName: 'Creepshow Casino',
    back: 'library',
    imageName: 'fifth/creepshowcasino',
  },
  'etpov': {
    cardName: 'Elysium: The Palace of Versailles',
    back: 'library',
    imageName: 'fifth/elysiumthepalaceofversailles',
  },
  'togrba': {
    cardName: 'Toreador Grand Ball',
    back: 'library',
    imageName: 'fifth/toreadorgrandball',
  },
  'villei': {
    cardName: 'Villein',
    back: 'library',
    imageName: 'fifth/villein',
  },
  'aiofel': {
    cardName: 'Aire of Elation',
    back: 'library',
    imageName: 'fifth/aireofelation',
  },
  'perpar': {
    cardName: 'Perfect Paragon',
    back: 'library',
    imageName: 'fifth/perfectparagon',
  },
  'reeagr': {
    cardName: 'Resist Earth’s Grasp',
    back: 'library',
    imageName: 'fifth/resistearthsgrasp',
  },
  'scaton': {
    cardName: 'Scalpel Tongue',
    back: 'library',
    imageName: 'fifth/scalpeltongue',
  },
  'ayelec': {
    cardName: 'Ayelech',
    back: 'crypt',
    imageName: 'fifth/ayelech',
  },
  'chrysa': {
    cardName: 'Chrysanthemum',
    back: 'crypt',
    imageName: 'fifth/chrysanthemum',
  },
  'inetri': {
    cardName: 'Ines Tristao',
    back: 'crypt',
    imageName: 'fifth/inestristao',
  },
  'lauren': {
    cardName: 'Lauren',
    back: 'crypt',
    imageName: 'fifth/lauren',
  },
  'llobro': {
    cardName: 'Lloyd Brooks',
    back: 'crypt',
    imageName: 'fifth/lloydbrooks',
  },
  'nassir': {
    cardName: 'Nassir',
    back: 'crypt',
    imageName: 'fifth/nassir',
  },
  'patsod': {
    cardName: 'Patrik Söderberg',
    back: 'crypt',
    imageName: 'fifth/patriksoderberg',
  },
  'roscor': {
    cardName: 'Rosalina Cortez',
    back: 'crypt',
    imageName: 'fifth/rosalinacortez',
  },
  'trepar': {
    cardName: 'Trevon Parker',
    back: 'crypt',
    imageName: 'fifth/trevonparker',
  },
  'pensub': {
    cardName: 'Pentex Subversion',
    back: 'library',
    imageName: 'fifth/pentextmsubversion',
  },
  'waanau': {
    cardName: 'Wasserschloss Anif, Austria',
    back: 'library',
    imageName: 'fifth/wasserschlossanifaustria',
  },
  '44mag': {
    cardName: '.44 Magnum',
    back: 'library',
    imageName: 'fifth/44magnum',
  },
  'boofco': {
    cardName: 'Bowl of Convergence',
    back: 'library',
    imageName: 'fifth/bowlofconvergence',
  },
  'kevves': {
    cardName: 'Kevlar Vest',
    back: 'library',
    imageName: 'fifth/kevlarvest',
  },
  'mirwal': {
    cardName: 'Mirror Walk',
    back: 'library',
    imageName: 'fifth/mirrorwalk',
  },
  'apport': {
    cardName: 'Apportation',
    back: 'library',
    imageName: 'fifth/apportation',
  },
  'precog': {
    cardName: 'Precognition',
    back: 'library',
    imageName: 'fifth/precognition',
  },
  'thspto': {
    cardName: 'The Spirit’s Touch',
    back: 'library',
    imageName: 'fifth/spiritstouch',
  },
  'aledra': {
    cardName: 'Alexa Draper',
    back: 'crypt',
    imageName: 'fifth/alexadraper',
  },
  'aliche': {
    cardName: 'Alice Chen',
    back: 'crypt',
    imageName: 'fifth/alicechen',
  },
  'broste': {
    cardName: 'Brock Sterling',
    back: 'crypt',
    imageName: 'fifth/brocksterling',
  },
  'chebla': {
    cardName: 'Chelsea Blake',
    back: 'crypt',
    imageName: 'fifth/chelseablake',
  },
  'hovobr': {
    cardName: 'Horst von Brühl',
    back: 'crypt',
    imageName: 'fifth/horstvonbruhl',
  },
  'madiso': {
    cardName: 'Madison',
    back: 'crypt',
    imageName: 'fifth/madison',
  },
  'naoste': {
    cardName: 'Naomi Stewart',
    back: 'crypt',
    imageName: 'fifth/naomistewart',
  },
  'oshdah': {
    cardName: 'Oshri Dahan',
    back: 'crypt',
    imageName: 'fifth/oshridahan',
  },
  'syvaoo': {
    cardName: 'Sybren van Oosten',
    back: 'crypt',
    imageName: 'fifth/sybrenvanoosten',
  },
  'vftc': {
    cardName: 'Visit From the Capuchin',
    back: 'library',
    imageName: 'fifth/visitfromthecapuchin',
  },
  'intimi': {
    cardName: 'Intimidation',
    back: 'library',
    imageName: 'fifth/intimidation',
  },
  'dathda': {
    cardName: 'Daring the Dawn',
    back: 'library',
    imageName: 'fifth/daringthedawn',
  },
  'hidstr': {
    cardName: 'Hidden Strength',
    back: 'library',
    imageName: 'fifth/hiddenstrength',
  },
  'tstd': {
    cardName: 'The Second Tradition: Domain',
    back: 'library',
    imageName: 'fifth/secondtraditiondomain',
  },
};
