import React, {useReducer} from "../../../_snowpack/pkg/react.js";
import {produce} from "../../../_snowpack/pkg/immer.js";
import {useSendMessage} from "../../hooks/useSendMessage.js";
import {Button} from "../../components/Button.js";
const allNames = [
  "Aaron",
  "Amie",
  "Arnie",
  "Bill",
  "Brand",
  "Brooklyn",
  "Callahan",
  "Collyn",
  "Cuthbert",
  "Daphne",
  "Faith",
  "Fran",
  "Glenna",
  "Goddard",
  "Herbert",
  "Isabel",
  "Jace",
  "Janie",
  "John",
  "Kate",
  "Kim",
  "Leighton",
  "Mirabelle",
  "Noble",
  "Paige",
  "Rosy",
  "Rudie",
  "Shirley",
  "Simon",
  "Stella",
  "Steve",
  "Ulyssa",
  "Vivyan",
  "Zoey"
];
const allDecks = ["malkavian", "nosferatu", "toreador", "tremere", "ventrue"];
const initialStarter = [];
const minPlayers = 2;
const maxPlayers = 5;
export function pickRandomValue(array) {
  return array[Math.floor(Math.random() * array.length)];
}
export function getRandomUnpicked(picks, all) {
  const pickedSet = new Set(picks);
  const unpicked = all.filter((value) => !pickedSet.has(value));
  const result = pickRandomValue(unpicked);
  return result;
}
export const reducer = (state, action) => {
  const {actionType} = action;
  if (actionType === "changeName") {
    const {index, value} = action;
    return produce(state, (draft) => {
      draft[index].name = value;
    });
  } else if (actionType === "delete") {
    const {index} = action;
    return produce(state, (draft) => {
      draft.splice(index, 1);
    });
  } else if (actionType === "changeDeck") {
    const {index, value} = action;
    return produce(state, (draft) => {
      draft[index].deckId = value;
    });
  } else if (actionType === "add") {
    const name = getRandomUnpicked(state.map((entry) => entry.name), allNames);
    const deckId = getRandomUnpicked(state.map((entry) => entry.deckId), allDecks);
    return produce(state, (draft) => {
      draft.push({name, deckId});
    });
  } else if (actionType === "reset") {
    let result = initialStarter;
    for (let i = 0; i < 3; ++i) {
      result = reducer(result, {actionType: "add"});
    }
    return result;
  }
  return state;
};
export function SetupSoloGameSection() {
  const sendMessage = useSendMessage();
  const [starter, dispatch] = useReducer(reducer, reducer(initialStarter, {actionType: "reset"}));
  const names = starter.map((entry) => entry.name);
  const canStart = minPlayers <= starter.length && starter.length <= maxPlayers && starter.every((entry) => {
    const {name} = entry;
    return name.length > 0;
  }) && new Set(names).size === names.length;
  const handleStartClick = () => {
    sendMessage({tag: "requestNewGame", gameTag: "mv", starter});
  };
  const handleSubmit = (event) => {
    console.log("will submit");
    event.preventDefault();
  };
  const handleNameInputChange = (index, event) => {
    const value = event.target.value;
    dispatch({actionType: "changeName", index, value});
  };
  const handleDelete = (index) => {
    dispatch({actionType: "delete", index});
  };
  const handleDeckChange = (index, event) => {
    const value = event.target.value;
    dispatch({actionType: "changeDeck", index, value});
  };
  const handleAdd = () => {
    dispatch({actionType: "add"});
  };
  const handleReset = () => {
    dispatch({actionType: "reset"});
  };
  return /* @__PURE__ */ React.createElement("section", {
    className: "SetupSoloGameSection"
  }, /* @__PURE__ */ React.createElement("h2", null, "Setup solo game"), /* @__PURE__ */ React.createElement("div", null, "Get acquainted with the UI by playing against yourself in the same window - 5th edition."), /* @__PURE__ */ React.createElement("form", {
    onSubmit: handleSubmit
  }, /* @__PURE__ */ React.createElement("ul", null, /* @__PURE__ */ React.createElement("li", {
    className: "buttons"
  }, /* @__PURE__ */ React.createElement(Button, {
    isDisabled: starter.length === maxPlayers,
    onClick: () => handleAdd(),
    isSecondary: true
  }, "+"), /* @__PURE__ */ React.createElement(Button, {
    onClick: () => handleReset(),
    isSecondary: true
  }, "Reset")), starter.map((entry, index) => {
    const {name, deckId} = entry;
    return /* @__PURE__ */ React.createElement("li", {
      key: index
    }, /* @__PURE__ */ React.createElement("input", {
      type: "text",
      value: name,
      onChange: (event) => handleNameInputChange(index, event)
    }), /* @__PURE__ */ React.createElement("select", {
      value: deckId,
      onChange: (event) => handleDeckChange(index, event)
    }, allDecks.map((deckId2) => {
      return /* @__PURE__ */ React.createElement("option", {
        key: deckId2
      }, deckId2);
    })), /* @__PURE__ */ React.createElement(Button, {
      onClick: () => handleDelete(index),
      isSecondary: true
    }, "–"));
  }))), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Button, {
    isDisabled: !canStart,
    onClick: handleStartClick
  }, "Start")));
}
