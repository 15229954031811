import React from "../../../_snowpack/pkg/react.js";
import {useSelector} from "../../../_snowpack/pkg/react-redux.js";
import {CardRow} from "../CardRow.js";
import {createSelectZoneByOwnerAndSlug} from "../../slices/mv/mvSlice.js";
import {MvCardList} from "./MvCardList.js";
export function MvZoneCardRow(props) {
  const {owner, slug, hasSpacedCards} = props;
  const zone = useSelector(createSelectZoneByOwnerAndSlug(owner, slug));
  const {cards} = zone;
  return /* @__PURE__ */ React.createElement(CardRow, {
    hasSpacedCards
  }, /* @__PURE__ */ React.createElement(MvCardList, {
    cards
  }));
}
