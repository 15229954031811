import {orderLabelTemplates} from "./translations.js";
import {baseToCardInfo} from "./data/cards.js";
export function getCardSrc(imageName) {
  if (imageName === null) {
    return null;
  }
  return `/${imageName}.jpg`;
}
export function getCardBackSrc(cardBackType) {
  return cardBackType === "crypt" ? "/card-back/vtes-crypt.jpg" : "/card-back/vtes-library.jpg";
}
export function makeOrderRows(orders, source) {
  const orderRows = [];
  const cidFromSource = (source2) => {
    return source2.split("~")[1];
  };
  function createSingleOrderRow(order, orderItem) {
    const orderRow = {
      type: "single",
      orderItem
    };
    orderRows.push(orderRow);
  }
  function addOrderItem(order, orderItem) {
    const {category} = order;
    if (category) {
      let orderRow = orderRows.find((row) => row.category === category);
      if (!orderRow) {
        orderRow = {
          type: "multi",
          category,
          orderItems: [orderItem]
        };
        orderRows.push(orderRow);
      } else {
        orderRow.orderItems.push(orderItem);
      }
    } else {
      createSingleOrderRow(order, orderItem);
    }
  }
  function createSingleRowsByNumber(order, itemMaker) {
    const {min, max} = order;
    for (let number = min; number <= max; ++number) {
      const orderItem = itemMaker(number);
      createSingleOrderRow(order, orderItem);
    }
  }
  function createRowsWithMaker(order, itemMaker) {
    const {category} = order;
    if (category) {
      const number = 1;
      const orderItem = itemMaker(number);
      addOrderItem(order, orderItem);
    } else {
      createSingleRowsByNumber(order, itemMaker);
    }
  }
  for (const order of orders) {
    const {token, orderType, orderGroup} = order;
    let labelTemplate = orderLabelTemplates[orderGroup];
    if (!labelTemplate) {
      console.warn("No label template for order group", orderGroup, order);
      labelTemplate = `?${orderGroup}?`;
    }
    switch (orderType) {
      case "*chooseTargetCid*": {
        const cid = cidFromSource(source);
        const {filledOrder} = order;
        const orderItem = {
          oiid: `${token}:${cid}`,
          label: labelTemplate,
          filledOrder,
          order
        };
        addOrderItem(order, orderItem);
        break;
      }
      case "chooseCidAndNumber": {
        const itemMaker = (number) => {
          const filledOrder = {
            token,
            number
          };
          const oiid = `${token}:${number}`;
          const label = labelTemplate.replace("%n", number);
          const orderItem = {
            oiid,
            label,
            filledOrder,
            order
          };
          return orderItem;
        };
        createRowsWithMaker(order, itemMaker);
        break;
      }
      case "chooseCidAndTargetCid": {
        const partiallyFilledOrder = {
          token
        };
        const orderItem = {
          oiid: `${token}`,
          label: labelTemplate,
          partiallyFilledOrder,
          nextStep: {
            stepType: "chooseTargetCid",
            targetCids: order.targetCids
          },
          order
        };
        addOrderItem(order, orderItem);
        break;
      }
      case "chooseRoleAndNumber": {
        const itemMaker = (number) => {
          const label = labelTemplate.replace("%n", number);
          const filledOrder = {
            token,
            number
          };
          const orderItem = {
            oiid: `${token}:${number}`,
            label,
            filledOrder,
            order
          };
          return orderItem;
        };
        createRowsWithMaker(order, itemMaker);
        break;
      }
      case "chooseRole": {
        const filledOrder = {
          token
        };
        const orderItem = {
          oiid: token,
          label: labelTemplate,
          filledOrder,
          order
        };
        addOrderItem(order, orderItem);
        break;
      }
      case "simple":
      case "chooseCid": {
        const filledOrder = {
          token
        };
        const orderItem = {
          oiid: token,
          label: labelTemplate,
          filledOrder,
          order
        };
        addOrderItem(order, orderItem);
        break;
      }
      default: {
        console.warn("Unrecognized orderType", orderType, order);
        break;
      }
    }
  }
  return orderRows;
}
export const CARD_CID_POLICY = "all";
export function formatTimestamp(timestamp) {
  const pad = (s) => {
    s = s.toString();
    return s.length < 2 ? "0" + s : s;
  };
  const d = new Date(timestamp);
  return `${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}`;
}
export function makePlayerBadgeTitle(strToShow) {
  const shownStrs = Object.entries(strToShow).filter((x) => x[1]).map((x) => x[0]);
  return shownStrs.length > 0 ? shownStrs.join(" | ") : null;
}
export function baseFromCid(cid) {
  const m = cid.match(/([^-#]+)([-#]\d+)?/);
  if (m === null) {
    console.error("cid is not proper", cid);
    return null;
  }
  const base = m[1];
  return base;
}
export function imageNameFromCid(cid, options = {}) {
  const base = baseFromCid(cid);
  const cardInfo = baseToCardInfo[base];
  if (!cardInfo) {
    if (options.ignoreIfNotFound) {
      return null;
    }
    console.error("Cannot find cardInfo for cid", cid);
    return "UNKNOWN";
  }
  return cardInfo.imageName;
}
export function getVar(vars, path) {
  const varName = path.join(":");
  return vars[varName];
}
export function arrangeRoles(roles, roleToPlayerName, yourUsername) {
  const displayedRoles = [...roles];
  displayedRoles.reverse();
  let index = roles.findIndex((role) => {
    return roleToPlayerName[role] === yourUsername;
  });
  if (index === -1) {
    return displayedRoles;
  }
  const n = roles.length;
  const requiredIndex = Math.ceil(n / 2 - 1);
  while (index !== requiredIndex) {
    index = (index + 1) % n;
    displayedRoles.push(displayedRoles.shift());
  }
  return displayedRoles;
}
