import {
  getActivePlayerRole,
  getPlayingRoles,
  getNonActiveRoles
} from "./mvUtils.js";
import {applyAction} from "../actionsInfrastructure.js";
import {mva} from "./mvActions.js";
import {fa} from "../foundationActions.js";
import {
  generalSource,
  makeCardSource,
  makePileSource,
  makePlayerSource,
  createOrderGroup,
  registerOrderGroup
} from "../ordersInfrastructure.js";
import {
  getCardsInPlayerZone,
  buildZoneName,
  getPlayerZone,
  getZoneName,
  getStateVar,
  qualifiedName,
  splitZoneName,
  walkCards
} from "../utils.js";
import {
  havingBloodSlugs,
  mvDefaultMinionVarValues,
  inPlaySlugs,
  minionSlugs
} from "./mvConstants.js";
export const mvSearchingLibraryOrders = createOrderGroup("mvSearchingLibrary", {
  precondition(state) {
    return state.vars.specialSequence === "searchingLibrary";
  }
}, {
  libraryToHand: {
    collect(state) {
      return walkCards(state, {
        roles: getPlayingRoles(state),
        slugs: "library"
      }, (card, location, role) => {
        if (card.cardBack !== "library") {
          return null;
        }
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          }
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role, source} = order;
      const {card} = source;
      return applyAction(state, fa.seq(fa.moveCard(card.sid, buildZoneName(role, "hand"), {
        sourceLocation: order.additionalData.location,
        saveAs: "card"
      }), fa.text("t-library-to-hand-increase-hand-size", {
        r: {value: role},
        c: {fromStash: "card"}
      })));
    }
  },
  stopSearchingLibrary: {
    collect(state) {
      const role = state.vars.searchingLibraryRole;
      return {
        role,
        orderType: "simple",
        source: generalSource
      };
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      const zone = getPlayerZone(state, role, "library");
      return applyAction(state, fa.seq(fa.setZoneAccess(getZoneName(zone), []), fa.setVars({
        searchingLibraryRole: null,
        specialSequence: false
      }), fa.text("t-cancel-search-library", {r: {value: role}})));
    }
  }
});
registerOrderGroup(mvSearchingLibraryOrders);
export const mvSearchingCryptOrders = createOrderGroup("mvSearchingCrypt", {
  precondition(state) {
    return state.vars.specialSequence === "searchingCrypt";
  }
}, {
  cryptToUncontrolled: {
    collect(state) {
      return walkCards(state, {
        roles: getPlayingRoles(state),
        slugs: "crypt"
      }, (card, location, role) => {
        if (card.cardBack !== "crypt") {
          return null;
        }
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          }
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role, source} = order;
      const {card} = source;
      return applyAction(state, fa.seq(fa.moveCard(card.sid, buildZoneName(role, "uncontrolled"), {
        facing: "facedown",
        sourceLocation: order.additionalData.location,
        saveAs: "card"
      }), fa.text("t-crypt-to-uncontrolled", {
        r: {value: role},
        c: {fromStash: "card"}
      })));
    }
  },
  stopSearchingCrypt: {
    collect(state) {
      const role = state.vars.searchingCryptRole;
      return {
        role,
        orderType: "simple",
        source: generalSource
      };
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      const zone = getPlayerZone(state, role, "crypt");
      return applyAction(state, fa.seq(fa.setZoneAccess(getZoneName(zone), []), fa.setVars({
        searchingCryptRole: null,
        specialSequence: null
      }), fa.text("t-cancel-search-crypt", {r: {value: role}})));
    }
  }
});
registerOrderGroup(mvSearchingCryptOrders);
export const mvGeneralOrders = createOrderGroup("mvGeneral", {
  precondition(state) {
    return !getStateVar(state, "specialSequence");
  }
}, {
  playReplacing: {
    collect(state) {
      return walkCards(state, {
        roles: getPlayingRoles(state),
        slugs: "hand"
      }, (card, location, role) => {
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          }
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      const {card} = order.source;
      return applyAction(state, fa.seq(fa.moveCard(card.sid, buildZoneName(role, "current"), {
        sourceLocation: order.additionalData.location,
        saveAs: "played"
      }), mva.drawCard(role, {saveAs: "drawn"}), fa.text("t-play-replacing", {
        r: {value: role},
        c1: {fromStash: "played"},
        c2: {fromStash: "drawn"}
      })));
    }
  },
  discardReplacing: {
    collect(state) {
      return walkCards(state, {
        roles: getPlayingRoles(state),
        slugs: "hand"
      }, (card, location, role) => {
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          }
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      const {card} = order.source;
      return applyAction(state, fa.seq(fa.moveCard(card.sid, buildZoneName(role, "ashHeap"), {
        sourceLocation: order.additionalData.location,
        saveAs: "c1"
      }), mva.drawCard(role, {saveAs: "c2"}), fa.text("t-discard-replacing", {
        r: {value: role},
        c1: {fromStash: "c1"},
        c2: {fromStash: "c2"}
      })));
    }
  },
  playDecreaseHandSize: {
    collect(state) {
      return walkCards(state, {
        roles: getPlayingRoles(state),
        slugs: "hand"
      }, (card, location, role) => {
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          }
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role, source} = order;
      const {card} = source;
      return applyAction(state, fa.seq(fa.moveCard(card.sid, buildZoneName(role, "current"), {
        sourceLocation: order.additionalData.location,
        saveAs: "card"
      }), fa.text("t-play-decrease-hand-size", {
        r: {value: role},
        c1: {fromStash: "card"}
      })));
    }
  },
  discardDecreaseHandSize: {
    collect(state) {
      return walkCards(state, {
        roles: getPlayingRoles(state),
        slugs: "hand"
      }, (card, location, role) => {
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          }
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role, source} = order;
      const {card} = source;
      return applyAction(state, fa.seq(fa.moveCard(card.sid, buildZoneName(role, "ashHeap"), {
        sourceLocation: order.additionalData.location,
        saveAs: "card"
      }), fa.text("t-discard-decrease-hand-size", {
        r: {value: role},
        c1: {fromStash: "card"}
      })));
    }
  },
  burnBlood: {
    collect(state) {
      return walkCards(state, {
        roles: getPlayingRoles(state),
        slugs: havingBloodSlugs,
        skipAttachments: true
      }, (card, location, role) => {
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          },
          isDisabled: card.vars.blood === 0,
          isRepeatable: true,
          orderRow: "blood"
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      const {card} = order.source;
      return applyAction(state, fa.seq(fa.changeCardVars(card.sid, {
        blood: -1
      }, {
        location: order.additionalData.location,
        saveAs: "c",
        saveNewValueAs: ["blood", "n2"]
      }), fa.text("t-burn-blood", {
        r: {value: role},
        c: {fromStash: "c"},
        n1: {value: 1},
        n2: {fromStash: "n2"}
      })));
    }
  },
  addBlood: {
    collect(state) {
      return walkCards(state, {
        roles: getPlayingRoles(state),
        slugs: havingBloodSlugs,
        skipAttachments: true
      }, (card, location, role) => {
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          },
          isRepeatable: true,
          orderRow: "blood"
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      const {card} = order.source;
      return applyAction(state, fa.seq(fa.changeCardVars(card.sid, {
        blood: 1
      }, {
        location: order.additionalData.location,
        saveAs: "c",
        saveNewValueAs: ["blood", "n2"]
      }), fa.text("t-add-blood", {
        r: {value: role},
        c: {fromStash: "c"},
        n1: {value: 1},
        n2: {fromStash: "n2"}
      })));
    }
  },
  transferTake: {
    collect(state) {
      const role = getActivePlayerRole(state);
      return walkCards(state, {
        roles: role,
        slugs: "uncontrolled",
        skipAttachments: true
      }, (card, location) => {
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          },
          isDisabled: card.vars.blood === 0,
          isRepeatable: true,
          orderRow: "transfer"
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      const {card} = order.source;
      return applyAction(state, fa.seq(fa.changeCardVars(card.sid, {
        blood: -1
      }, {
        saveAs: "c",
        saveNewValueAs: ["blood", "n3"]
      }), mva.changePool(role, 1, {saveNewValueAs: "n2"}), fa.text("t-transfer-take", {
        r: {value: role},
        n1: {value: 1},
        n2: {fromStash: "n2"},
        n3: {fromStash: "n3"},
        c: {fromStash: "c"}
      })));
    }
  },
  transferPut: {
    collect(state) {
      const role = getActivePlayerRole(state);
      return walkCards(state, {
        roles: role,
        slugs: "uncontrolled",
        skipAttachments: true
      }, (card, location) => {
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          },
          isRepeatable: true,
          orderRow: "transfer"
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      const {card} = order.source;
      return applyAction(state, fa.seq(fa.changeCardVars(card.sid, {
        blood: 1
      }, {
        saveAs: "c",
        saveNewValueAs: ["blood", "n3"]
      }), mva.changePool(role, -1, {saveNewValueAs: "n2"}), fa.text("t-transfer-put", {
        r: {value: role},
        n1: {value: 1},
        n2: {fromStash: "n2"},
        n3: {fromStash: "n3"},
        c: {fromStash: "c"}
      })));
    }
  },
  drawIncreaseHandSize: {
    collect(state) {
      const role = getActivePlayerRole(state);
      const zone = getPlayerZone(state, role, "library");
      return {
        role,
        orderType: "simpleOnPile",
        source: makePileSource(zone),
        isDisabled: zone.cards.length === 0
      };
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      return applyAction(state, fa.seq(mva.drawCard(role, {saveAs: "c"}), fa.text("t-draw-increase-hand-size", {
        r: {value: role},
        c: {fromStash: "c"}
      })));
    }
  },
  searchLibrary: {
    collect(state) {
      const role = getActivePlayerRole(state);
      const zone = getPlayerZone(state, role, "library");
      return {
        role,
        orderType: "simpleOnPile",
        source: makePileSource(zone),
        isDisabled: zone.cards.length === 0
      };
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      const zone = getPlayerZone(state, role, "library");
      return applyAction(state, fa.seq(fa.setZoneAccess(getZoneName(zone), [role]), fa.setVars({
        searchingLibraryRole: role,
        specialSequence: "searchingLibrary"
      }), fa.text("t-search-library", {r: {value: role}})));
    }
  },
  transferRecruit: {
    collect(state) {
      const role = getActivePlayerRole(state);
      const zone = getPlayerZone(state, role, "crypt");
      return {
        role,
        orderType: "simpleOnPile",
        source: makePileSource(zone),
        isDisabled: zone.cards.length === 0
      };
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      return applyAction(state, fa.seq(mva.changePool(role, -1, {saveNewValueAs: "n"}), mva.recruit(role, {saveAs: "c"}), fa.text("t-transfer-recruit", {
        r: {value: role},
        c: {fromStash: "c"},
        n: {fromStash: "n"}
      })));
    }
  },
  recruit: {
    collect(state) {
      const role = getActivePlayerRole(state);
      const zone = getPlayerZone(state, role, "crypt");
      return {
        role,
        orderType: "simpleOnPile",
        source: makePileSource(zone),
        isDisabled: zone.cards.length === 0
      };
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      return applyAction(state, fa.seq(mva.recruit(role, {saveAs: "c"}), fa.text("t-recruit", {
        r: {value: role},
        c: {fromStash: "c"}
      })));
    }
  },
  searchCrypt: {
    collect(state) {
      const role = getActivePlayerRole(state);
      const zone = getPlayerZone(state, role, "crypt");
      return {
        role,
        orderType: "simpleOnPile",
        source: makePileSource(zone),
        isDisabled: zone.cards.length === 0
      };
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      const zone = getPlayerZone(state, role, "crypt");
      return applyAction(state, fa.seq(fa.setZoneAccess(getZoneName(zone), [role]), fa.setVars({
        searchingCryptRole: role,
        specialSequence: "searchingCrypt"
      }), fa.text("t-search-crypt", {r: {value: role}})));
    }
  },
  burnPool: {
    collect(state) {
      return getPlayingRoles(state).map((role) => {
        return {
          role,
          orderType: "simpleOnPlayer",
          source: makePlayerSource(role),
          isRepeatable: true,
          orderRow: "pool"
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      return applyAction(state, fa.seq(mva.changePool(role, -1, {saveNewValueAs: "n2"}), fa.text("t-burn-pool", {
        r: {value: role},
        n1: {value: 1},
        n2: {fromStash: "n2"}
      })));
    }
  },
  gainPool: {
    collect(state) {
      return getPlayingRoles(state).map((role) => {
        return {
          role,
          orderType: "simpleOnPlayer",
          source: makePlayerSource(role),
          isRepeatable: true,
          orderRow: "pool"
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      return applyAction(state, fa.seq(mva.changePool(role, 1, {saveNewValueAs: "n2"}), fa.text("t-gain-pool", {
        r: {value: role},
        n1: {value: 1},
        n2: {fromStash: "n2"}
      })));
    }
  },
  burnEdge: {
    collect(state) {
      const role = state.vars.edgeRole;
      if (!role) {
        return null;
      }
      return {
        role,
        orderType: "simpleOnPlayer",
        source: makePlayerSource(role),
        orderRow: "edge"
      };
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      return applyAction(state, fa.seq(fa.setVars({edgeRole: null}), fa.text("t-burn-edge", {
        r: {value: role}
      })));
    }
  },
  gainEdge: {
    collect(state) {
      const roles = getPlayingRoles(state);
      const edgeRole = getStateVar(state, "edgeRole", null);
      return roles.map((role) => {
        if (role === edgeRole) {
          return null;
        }
        return {
          role,
          orderType: "simpleOnPlayer",
          source: makePlayerSource(role),
          orderRow: "edge"
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      return applyAction(state, fa.seq(fa.setVars({edgeRole: role}), fa.text("t-gain-edge", {
        r: {value: role}
      })));
    }
  },
  loseVp: {
    collect(state) {
      return getPlayingRoles(state).map((role) => {
        const vp = getStateVar(state, qualifiedName(role, "vp"));
        if (vp === 0) {
          return null;
        }
        return {
          role,
          orderType: "simpleOnPlayer",
          source: makePlayerSource(role),
          orderRow: "vp"
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      return applyAction(state, fa.seq(mva.changeVp(role, -1, {saveNewValueAs: "n2"}), fa.text("t-lose-vp", {
        r: {value: role},
        n1: {value: 1},
        n2: {fromStash: "n2"}
      })));
    }
  },
  gainVp: {
    collect(state) {
      return getPlayingRoles(state).map((role) => {
        return {
          role,
          orderType: "simpleOnPlayer",
          source: makePlayerSource(role),
          orderRow: "vp"
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      return applyAction(state, fa.seq(mva.changeVp(role, 1, {saveNewValueAs: "n2"}), fa.text("t-gain-vp", {
        r: {value: role},
        n1: {value: 1},
        n2: {fromStash: "n2"}
      })));
    }
  },
  currentToAshHeap: {
    collect(state) {
      return walkCards(state, {
        roles: getPlayingRoles(state),
        slugs: "current"
      }, (card, location, role) => {
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          }
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role, source} = order;
      const {card} = source;
      return applyAction(state, fa.seq(fa.moveCard(card.sid, buildZoneName(role, "ashHeap"), {
        sourceLocation: order.additionalData.location,
        saveAs: "c1"
      }), fa.text("t-current-discard", {
        r: {value: role},
        c1: {fromStash: "c1"}
      })));
    }
  },
  currentToSupport: {
    collect(state) {
      return walkCards(state, {
        roles: getPlayingRoles(state),
        slugs: "current"
      }, (card, location, role) => {
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          }
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role, source} = order;
      const {card} = source;
      return applyAction(state, fa.seq(fa.moveCard(card.sid, buildZoneName(role, "support"), {
        sourceLocation: order.additionalData.location
      }), fa.setCardVars(card.sid, {
        blood: 0
      }, {
        saveAs: "c1"
      }), fa.text("t-current-to-support", {
        r: {value: role},
        c1: {fromStash: "c1"}
      })));
    }
  },
  currentToReady: {
    collect(state) {
      return walkCards(state, {
        roles: getPlayingRoles(state),
        slugs: "current"
      }, (card, location, role) => {
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          }
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role, source} = order;
      const {card} = source;
      return applyAction(state, fa.seq(fa.moveCard(card.sid, buildZoneName(role, "ready"), {
        sourceLocation: order.additionalData.location,
        saveAs: "c1"
      }), fa.text("t-current-to-ready", {
        r: {value: role},
        c1: {fromStash: "c1"}
      })));
    }
  },
  currentAttach: {
    collect(state) {
      return walkCards(state, {
        roles: getPlayingRoles(state),
        slugs: "current"
      }, (card, location, role) => {
        return {
          role,
          orderType: "cardWithAnotherCard",
          source: makeCardSource(card),
          additionalData: {
            location
          },
          nextLevelOrders: walkCards(state, {
            roles: role,
            slugs: inPlaySlugs,
            skipAttachments: true
          }, (nextCard, nextLocation, _nextRole) => {
            return {
              orderType: "simpleOnCard",
              orderName: "attachHere",
              source: makeCardSource(nextCard),
              additionalData: {
                location: nextLocation
              }
            };
          })
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role, source} = order;
      const {card} = source;
      const cid = filledOrder.nextLevelSource.split(":")[1];
      const registry = state.roleToCardRegistry[role];
      const hostSid = registry.cidToSid[cid];
      return applyAction(state, fa.seq(fa.attachCard(card.sid, hostSid, {
        cardLocation: order.additionalData.location,
        saveCardAs: "c",
        saveHostAs: "c2"
      }), fa.text("t-current-attach", {
        r: {value: role},
        c: {fromStash: "c"},
        c2: {fromStash: "c2"}
      })));
    }
  },
  unlockYourCards: {
    collect(state) {
      if (getStateVar(state, "actionInProgress")) {
        return null;
      }
      const role = getActivePlayerRole(state);
      const items = walkCards(state, {
        roles: role,
        slugs: inPlaySlugs
      }, (c, location, _role) => {
        const card = c;
        if (card.rotation !== "locked") {
          return null;
        }
        return {
          card,
          location
        };
      });
      if (items.length === 0) {
        return null;
      }
      return {
        role,
        orderType: "simple",
        source: generalSource,
        additionalData: {items}
      };
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      const {items} = order.additionalData;
      return applyAction(state, fa.seq(...items.map((item) => {
        const {card, location} = item;
        return mva.unlockCard(card.sid, {
          location
        });
      }), fa.text("t-unlock-your-cards", {r: {value: role}})));
    }
  },
  discardCurrentCards: {
    collect(state) {
      const canDiscard = state.roles.some((role) => {
        const cards = getCardsInPlayerZone(state, role, "current");
        return cards.length > 0;
      });
      if (!canDiscard) {
        return null;
      }
      return {
        role: getActivePlayerRole(state),
        orderType: "simple",
        source: generalSource
      };
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      return applyAction(state, fa.seq(mva.discardCurrentCards(), fa.text("t-discard-current-cards", {r: {value: role}})));
    }
  },
  endTurn: {
    collect(state) {
      const role = getActivePlayerRole(state);
      if (getStateVar(state, "actionInProgress")) {
        return null;
      }
      return {
        role,
        orderType: "simple",
        source: generalSource
      };
    },
    execute(state, order, filledOrder) {
      return applyAction(state, mva.endTurn());
    }
  },
  bringUncontrolledIntoPlay: {
    collect(state) {
      const role = getActivePlayerRole(state);
      return walkCards(state, {
        roles: role,
        slugs: "uncontrolled",
        skipAttachments: true
      }, (card, location) => {
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          }
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role, source} = order;
      const {card} = source;
      return applyAction(state, fa.seq(fa.moveCard(card.sid, buildZoneName(role, "ready"), {
        facing: "faceup",
        sourceLocation: order.additionalData.location,
        saveAs: "c"
      }), fa.setCardVars(card.sid, {
        ...mvDefaultMinionVarValues
      }), fa.text("t-get-ready", {
        r: {value: role},
        c: {fromStash: "c"}
      })));
    }
  },
  declareActingAndLock: {
    collect(state) {
      if (getStateVar(state, "actionInProgress")) {
        return null;
      }
      return walkCards(state, {
        roles: getActivePlayerRole(state),
        slugs: minionSlugs,
        skipAttachments: true
      }, (card, location, role) => {
        if (card.rotation !== "ready") {
          return null;
        }
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          }
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role, source} = order;
      const {card} = source;
      return applyAction(state, fa.seq(mva.lockCard(card.sid, {
        location: order.additionalData.location
      }), fa.setCardVars(card.sid, {
        isActing: true
      }, {
        location: order.additionalData.location,
        saveAs: "c"
      }), fa.setVars({
        actionInProgress: true
      }), fa.text("t-declare-acting-and-lock", {
        r: {value: role},
        c: {fromStash: "c"}
      })));
    }
  },
  declareBlocking: {
    collect(state) {
      if (!getStateVar(state, "actionInProgress")) {
        return null;
      }
      return walkCards(state, {
        roles: getNonActiveRoles(state),
        slugs: minionSlugs,
        skipAttachments: true
      }, (card, location, role) => {
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          }
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role, source} = order;
      const {card} = source;
      return applyAction(state, fa.seq(fa.setCardVars(card.sid, {
        isBlocking: true
      }, {
        location: order.additionalData.location,
        saveAs: "c"
      }), fa.text("t-declare-blocking", {
        r: {value: role},
        c: {fromStash: "c"}
      })));
    }
  },
  endAction: {
    collect(state) {
      if (!getStateVar(state, "actionInProgress")) {
        return null;
      }
      const role = getActivePlayerRole(state);
      return {
        role,
        orderType: "simple",
        source: generalSource
      };
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      const clearStatusActions = walkCards(state, {
        roles: getPlayingRoles(state),
        slugs: minionSlugs
      }, (card, location, role2) => {
        const {vars} = card;
        if (vars.isActing) {
          return fa.setCardVars(card.sid, {
            isActing: false
          }, {
            location
          });
        } else if (vars.isBlocking) {
          return fa.setCardVars(card.sid, {
            isBlocking: false
          }, {
            location
          });
        }
        return null;
      });
      return applyAction(state, fa.seq(fa.setVars({
        actionInProgress: false
      }), ...clearStatusActions, mva.discardCurrentCards(), fa.text("t-end-action", {
        r: {value: role}
      })));
    }
  },
  inPlayLock: {
    collect(state) {
      return walkCards(state, {
        roles: getPlayingRoles(state),
        slugs: inPlaySlugs
      }, (card, location, role) => {
        if (card.rotation !== "ready") {
          return null;
        }
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          }
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role, source} = order;
      const {card} = source;
      return applyAction(state, fa.seq(mva.lockCard(card.sid, {
        location: order.additionalData.location,
        saveAs: "c"
      }), fa.text("t-in-play-lock", {
        r: {value: role},
        c: {fromStash: "c"}
      })));
    }
  },
  inPlayUnlock: {
    collect(state) {
      return walkCards(state, {
        roles: getPlayingRoles(state),
        slugs: inPlaySlugs
      }, (card, location, role) => {
        if (card.rotation !== "locked") {
          return null;
        }
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          }
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role, source} = order;
      const {card} = source;
      return applyAction(state, fa.seq(mva.unlockCard(card.sid, {
        location: order.additionalData.location,
        saveAs: "c"
      }), fa.text("t-in-play-unlock", {
        r: {value: role},
        c: {fromStash: "c"}
      })));
    }
  },
  inPlayTurnFacedown: {
    collect(state) {
      return walkCards(state, {
        roles: getPlayingRoles(state),
        slugs: inPlaySlugs
      }, (card, location, role) => {
        if (card.facing !== "faceup") {
          return null;
        }
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          }
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role, source} = order;
      const {card} = source;
      return applyAction(state, fa.seq(fa.changeCardFacing(card.sid, "facedown", {
        location: order.additionalData.location,
        saveAs: "c"
      }), fa.text("t-in-play-turn-facedown", {
        r: {value: role},
        c: {fromStash: "c"}
      })));
    }
  },
  inPlayTurnFaceup: {
    collect(state) {
      return walkCards(state, {
        roles: getPlayingRoles(state),
        slugs: inPlaySlugs
      }, (card, location, role) => {
        if (card.facing !== "facedown") {
          return null;
        }
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          }
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role, source} = order;
      const {card} = source;
      return applyAction(state, fa.seq(fa.changeCardFacing(card.sid, "faceup", {
        location: order.additionalData.location,
        saveAs: "c"
      }), fa.text("t-in-play-turn-faceup", {
        r: {value: role},
        c: {fromStash: "c"}
      })));
    }
  },
  readyToTorpor: {
    collect(state) {
      return walkCards(state, {
        roles: getPlayingRoles(state),
        slugs: "ready",
        skipAttachments: true
      }, (card, location, role) => {
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          }
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role, source} = order;
      const {card} = source;
      return applyAction(state, fa.seq(fa.moveCard(card.sid, buildZoneName(role, "torpor"), {
        sourceLocation: order.additionalData.location,
        saveAs: "c"
      }), fa.text("t-ready-to-torpor", {
        r: {value: role},
        c: {fromStash: "c"}
      })));
    }
  },
  torporToReady: {
    collect(state) {
      return walkCards(state, {
        roles: getPlayingRoles(state),
        slugs: "torpor",
        skipAttachments: true
      }, (card, location, role) => {
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          }
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role, source} = order;
      const {card} = source;
      return applyAction(state, fa.seq(fa.moveCard(card.sid, buildZoneName(role, "ready"), {
        sourceLocation: order.additionalData.location,
        saveAs: "c"
      }), fa.text("t-torpor-to-ready", {
        r: {value: role},
        c: {fromStash: "c"}
      })));
    }
  },
  inPlayBurn: {
    collect(state) {
      return walkCards(state, {
        roles: getPlayingRoles(state),
        slugs: inPlaySlugs
      }, (card, location, role) => {
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          }
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role, source} = order;
      const {card} = source;
      return applyAction(state, fa.seq(mva.burnCard(card.sid, {
        location: order.additionalData.location,
        saveAs: "c"
      }), fa.text("t-in-play-burn", {
        r: {value: role},
        c: {fromStash: "c"}
      })));
    }
  },
  inPlayToActivePlayer: {
    collect(state) {
      return walkCards(state, {
        roles: getNonActiveRoles(state),
        slugs: inPlaySlugs,
        skipAttachments: true
      }, (card, location, role) => {
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          }
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role, source} = order;
      const {card} = source;
      const location = order.additionalData.location;
      const [_owner, slug] = splitZoneName(location.zoneName);
      const activePlayerRole = getActivePlayerRole(state);
      return applyAction(state, fa.seq(fa.moveCard(card.sid, buildZoneName(activePlayerRole, slug), {
        sourceLocation: order.additionalData.location,
        saveAs: "c"
      }), fa.text("t-in-play-to-acting-player", {
        r: {value: role},
        r2: {value: activePlayerRole},
        c: {fromStash: "c"}
      })));
    }
  },
  attachedReattach: {
    collect(state) {
      return walkCards(state, {
        roles: getPlayingRoles(state),
        slugs: inPlaySlugs,
        onlyAttachments: true
      }, (card, location, role) => {
        const targets = walkCards(state, {
          roles: getPlayingRoles(state),
          slugs: inPlaySlugs,
          skipAttachments: true
        }, (targetCard, targetLocation, targetRole) => {
          if (targetLocation.zoneName === location.zoneName && targetLocation.index === location.index) {
            return null;
          }
          return {
            orderType: "simpleOnCard",
            orderName: "attachHere",
            source: makeCardSource(targetCard),
            additionalData: {
              location: targetLocation
            }
          };
        }).filter((x) => x !== null);
        if (targets.length === 0) {
          return null;
        }
        return {
          role,
          orderType: "cardWithAnotherCard",
          source: makeCardSource(card),
          additionalData: {
            location
          },
          nextLevelOrders: targets
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role, source} = order;
      const {card} = source;
      const cid = filledOrder.nextLevelSource.split(":")[1];
      const registry = state.roleToCardRegistry[role];
      const hostSid = registry.cidToSid[cid];
      return applyAction(state, fa.seq(fa.attachCard(card.sid, hostSid, {
        cardLocation: order.additionalData.location,
        saveCardAs: "c",
        saveHostAs: "c2"
      }), fa.text("t-attached-reattach", {
        r: {value: role},
        c: {fromStash: "c"},
        c2: {fromStash: "c2"}
      })));
    }
  },
  ashHeapToHand: {
    collect(state) {
      return walkCards(state, {
        roles: getPlayingRoles(state),
        slugs: "ashHeap"
      }, (card, location, role) => {
        if (card.cardBack !== "library") {
          return null;
        }
        return {
          role,
          orderType: "simpleOnCard",
          source: makeCardSource(card),
          additionalData: {
            location
          }
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role, source} = order;
      const {card} = source;
      return applyAction(state, fa.seq(fa.moveCard(card.sid, buildZoneName(role, "hand"), {
        sourceLocation: order.additionalData.location,
        saveAs: "card"
      }), fa.text("t-ash-heap-to-hand", {
        r: {value: role},
        c: {fromStash: "card"}
      })));
    }
  },
  getOusted: {
    collect(state) {
      return getNonActiveRoles(state).map((role) => {
        return {
          role,
          orderType: "simpleOnPlayer",
          source: makePlayerSource(role)
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      return applyAction(state, fa.seq(mva.oustPlayer(role), fa.text("t-oust", {
        r: {value: role}
      })));
    }
  },
  indicatePleaseWait: {
    collect(state) {
      return getPlayingRoles(state).map((role) => {
        return {
          role,
          orderType: "simple",
          source: generalSource
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      return applyAction(state, fa.seq(fa.text("t-indicate-please-wait", {
        r: {value: role}
      })));
    }
  },
  indicateNoReaction: {
    collect(state) {
      return getPlayingRoles(state).map((role) => {
        return {
          role,
          orderType: "simple",
          source: generalSource
        };
      });
    },
    execute(state, order, filledOrder) {
      const {role} = order;
      return applyAction(state, fa.seq(fa.text("t-indicate-no-reaction", {
        r: {value: role}
      })));
    }
  }
});
registerOrderGroup(mvGeneralOrders);
