import React, {useState} from "../../_snowpack/pkg/react.js";
const val = {
  isOpen: false,
  imageName: null,
  cursorOnTrigger: false,
  cursorOnBigCard: false,
  isLeftSide: false,
  setCursorOnTrigger: (...args) => {
  },
  setCursorOnBigCard: (...args) => {
  }
};
export const BigCardContext = React.createContext(val);
BigCardContext.displayName = "BigCardContext";
export function getIsLeftSide(x) {
  const width = document.documentElement.clientWidth;
  return x < width / 2;
}
export function BigCardProvider(props) {
  const {children} = props;
  const [ctx, setCtx] = useState({
    isOpen: false,
    imageName: null,
    cursorOnTrigger: false,
    cursorOnBigCard: false,
    isLeftSide: false,
    setCursorOnTrigger(value, imageName = null, event = null) {
      const cursorOnTrigger = value;
      const {cursorOnBigCard} = ctx;
      const isOpen = cursorOnTrigger || cursorOnBigCard;
      const isLeftSide = event ? !getIsLeftSide(event.clientX) : false;
      setCtx({
        ...ctx,
        cursorOnTrigger,
        imageName,
        isOpen,
        isLeftSide
      });
    },
    setCursorOnBigCard(value) {
      const {cursorOnTrigger} = ctx;
      const cursorOnBigCard = value;
      const isOpen = cursorOnTrigger || cursorOnBigCard;
      setCtx({
        ...ctx,
        cursorOnBigCard,
        isOpen
      });
    }
  });
  return /* @__PURE__ */ React.createElement(BigCardContext.Provider, {
    value: ctx
  }, children);
}
