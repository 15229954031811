function isThisYear(date, today) {
  return today.getFullYear() === date.getFullYear();
}
function isToday(date, today) {
  return today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() === date.getDate();
}
const monthNames = "Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec".split(" ");
export function formatTimestamp(timestampStringOrDate) {
  let date;
  if (timestampStringOrDate instanceof Date) {
    date = timestampStringOrDate;
  } else {
    date = new Date(timestampStringOrDate);
  }
  function pad(n) {
    return n.toString().padStart(2, "0");
  }
  const today = new Date();
  const year = isThisYear(date, today) ? "" : `${date.getFullYear()}, `;
  const monthDay = isToday(date, today) ? "" : `${monthNames[date.getMonth()]} ${pad(date.getDate())} `;
  return `${monthDay}${year}${pad(date.getHours())}:${pad(date.getMinutes())}`;
}
