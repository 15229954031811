import {createSelector} from "../../../_snowpack/pkg/reselect.js";
const initialState = {
  connectionStatus: "loading",
  gameId: null
};
export function createCommonReducer(wsHandler) {
  return function commonReducer(common = initialState, action) {
    switch (action.type) {
      case "sendMessage": {
        const {message} = action;
        if (wsHandler) {
          wsHandler.sendMessage(message);
        }
        return common;
      }
      case "setConnectionStatus": {
        const {connectionStatus} = action;
        return {
          ...common,
          connectionStatus
        };
      }
      case "enterGameRoom": {
        const {gameId} = action;
        return {
          ...common,
          gameId
        };
      }
      case "clearGameId": {
        return {
          ...common,
          gameId: null
        };
      }
      default: {
        return common;
      }
    }
  };
}
export function selectCommon(state) {
  return state.common;
}
export const selectGameId = createSelector(selectCommon, (common) => common.gameId);
export const selectConnectionStatus = createSelector(selectCommon, (common) => common.connectionStatus);
