import React from "../../_snowpack/pkg/react.js";
import clsx from "../../_snowpack/pkg/clsx.js";
export function Button(props) {
  const {isDisabled, onClick, children, isSecondary, isDanger, className} = props;
  return /* @__PURE__ */ React.createElement("button", {
    type: "button",
    disabled: isDisabled,
    className: clsx("Button", className, {isSecondary, isDanger}),
    onClick
  }, children);
}
