import React from "../../../_snowpack/pkg/react.js";
import {Button} from "../../components/Button.js";
import {useSelector} from "../../../_snowpack/pkg/react-redux.js";
import {selectHomeSoloGames} from "../../slices/home/homeSlice.js";
import {formatTimestamp} from "../../utils/formatTimestamp.js";
import {DashSep} from "../../components/Sep.js";
import {useHistory} from "../../../_snowpack/pkg/react-router-dom.js";
import {useSendMessage} from "../../hooks/useSendMessage.js";
export function ActiveSoloGamesSection(props) {
  const soloGames = useSelector(selectHomeSoloGames);
  const sendMessage = useSendMessage();
  const history = useHistory();
  const handleResumeClick = (gid) => {
    const route = `/play/${gid}`;
    history.push(route);
  };
  const handleDeleteClick = (gid) => {
    sendMessage({tag: "home/deleteSoloGame", gid});
  };
  if (!soloGames || soloGames.length === 0) {
    return null;
  }
  return /* @__PURE__ */ React.createElement("section", {
    className: "ActiveSoloGamesSection"
  }, /* @__PURE__ */ React.createElement("h2", null, "Active solo games"), /* @__PURE__ */ React.createElement("ul", null, soloGames.map((soloGame) => {
    const {gid, players, lastModifiedAt} = soloGame;
    return /* @__PURE__ */ React.createElement("li", {
      key: gid
    }, /* @__PURE__ */ React.createElement("div", {
      className: "description"
    }, /* @__PURE__ */ React.createElement("span", null, players.join(", ")), /* @__PURE__ */ React.createElement(DashSep, null), /* @__PURE__ */ React.createElement("span", null, formatTimestamp(lastModifiedAt))), /* @__PURE__ */ React.createElement("div", {
      className: "buttons"
    }, /* @__PURE__ */ React.createElement(Button, {
      onClick: () => handleResumeClick(gid)
    }, "Resume"), /* @__PURE__ */ React.createElement(Button, {
      isDanger: true,
      onClick: () => handleDeleteClick(gid)
    }, "Delete")));
  })));
}
