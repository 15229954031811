import {createSlice, createSelector} from "../../../_snowpack/pkg/@reduxjs/toolkit.js";
import {arrangeRoles} from "../../utils.js";
import {qualifiedName, applyVDelta} from "../../../_snowpack/link/engine/src/main.js";
import {getKeyFromVOrder} from "./mvUtils.js";
export function convertVOrdersToSourceToVOrders(vOrders) {
  const result = {};
  vOrders.forEach((vOrder) => {
    const {source} = vOrder;
    if (!result[source]) {
      result[source] = [];
    }
    result[source].push(vOrder);
  });
  console.debug("sourceToVOrders", result);
  return result;
}
const mvSlice = createSlice({
  name: "mv",
  initialState: {
    username: null,
    vState: null,
    sourceToVOrders: null
  },
  reducers: {
    hello: (state, action) => {
      const {username, vState, vOrders} = action.payload;
      state.username = username;
      state.vState = vState;
      state.sourceToVOrders = convertVOrdersToSourceToVOrders(vOrders);
    },
    deltaArrived: (state, action) => {
      const {vDelta, vOrders} = action.payload;
      state.vState = applyVDelta(state.vState, vDelta);
      state.sourceToVOrders = convertVOrdersToSourceToVOrders(vOrders);
    }
  }
});
export const {hello, deltaArrived} = mvSlice.actions;
export const mvReducer = mvSlice.reducer;
export const createMvMessageHandler = (wsHandler) => {
  const selectActivePlayerRole = createSelectSingleStateVar("activePlayerRole");
  return {
    handleMessage(message) {
      const {tag} = message;
      switch (tag) {
        case "mv/hello": {
          const {username, vState, vOrders} = message;
          wsHandler.dispatch(hello({username, vState, vOrders}));
          return true;
        }
        case "mv/deltaArrived": {
          const {vDelta, vOrders} = message;
          const was = selectActivePlayerRole(wsHandler.store.getState());
          wsHandler.dispatch(deltaArrived({vDelta, vOrders}));
          const now = selectActivePlayerRole(wsHandler.store.getState());
          if (was !== now) {
            wsHandler.sendMessage({
              tag: "assumePovRole",
              role: now
            });
          }
          return true;
        }
      }
      return false;
    }
  };
};
const selectMv = (state) => state.mv;
function createVStateSelector(vStateToData) {
  return createSelector(selectMv, (mv) => {
    const {vState} = mv;
    if (!vState) {
      return null;
    }
    return vStateToData(vState);
  });
}
export const selectYourRole = createVStateSelector((vState) => vState.pov);
export const selectRoles = createVStateSelector((vState) => vState.roles);
export const selectRoleToPlayerName = createVStateSelector((vState) => vState.roleToPlayerName);
export const selectPlayerSectionRoles = createVStateSelector((vState) => {
  const {roles, roleToPlayerName, pov, vars} = vState;
  const playingRoles = roles.filter((role) => !vars[qualifiedName("ousted", role)]);
  return arrangeRoles(playingRoles, roleToPlayerName, roleToPlayerName[pov]);
});
export const selectStateVars = createVStateSelector((vState) => {
  return vState.vars;
});
export function createSelectPlayerNameByRole(role) {
  return createSelector(selectRoleToPlayerName, (roleToPlayerName) => {
    return roleToPlayerName[role];
  });
}
export function createSelectParticularStateVars(...varNames) {
  return createSelector(selectStateVars, (vars) => {
    if (!vars) {
      return varNames.map((x) => null);
    }
    return varNames.map((varName) => vars[varName]);
  });
}
export function createSelectSingleStateVar(varName) {
  return createSelector(selectStateVars, (vars) => {
    if (!vars) {
      return null;
    }
    return vars[varName];
  });
}
export const selectZones = createVStateSelector((vState) => {
  return vState.zones;
});
export function createSelectZoneByOwnerAndSlug(owner, slug) {
  return createSelector(selectZones, (zones) => {
    return zones[qualifiedName(owner, slug)];
  });
}
export const selectHistoryEntries = createVStateSelector((vState) => {
  return vState.historyEntries;
});
export function createSelectVOrdersBySource(source) {
  return createSelector(selectMv, (mv) => {
    if (!mv) {
      return [];
    }
    const {sourceToVOrders} = mv;
    if (!sourceToVOrders) {
      return [];
    }
    if (source === null) {
      return [];
    }
    return sourceToVOrders[source] || [];
  });
}
export function createSelectOrderRowsBySource(source) {
  return createSelector(createSelectVOrdersBySource(source), (vOrders) => {
    const rows = [];
    for (const vOrder of vOrders) {
      if (vOrder.orderRow) {
        const key = vOrder.orderRow;
        const row = rows.find((row2) => row2.key === key);
        if (!row) {
          rows.push({
            key,
            orders: [vOrder]
          });
        } else {
          row.orders.push(vOrder);
        }
      } else {
        rows.push({
          key: getKeyFromVOrder(vOrder),
          orders: [vOrder]
        });
      }
    }
    return rows;
  });
}
