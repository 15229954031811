import produce from "../../../pkg/immer.js";
import {registerCardChange} from "./CardRegistry.js";
import {getZone, createAttachmentsRegion} from "./utils.js";
import {EError} from "./errors.js";
export function removeCardAtLocation(state, location) {
  const {zoneName, index, attachmentIndex} = location;
  const zone = getZone(state, zoneName);
  let card = zone.cards[index];
  let newZone;
  if (attachmentIndex === void 0) {
    newZone = produce(zone, (draftZone) => {
      draftZone.cards.splice(index, 1);
    });
  } else {
    if (card.tag !== "cardInRegion") {
      throw new Error(`cannot remove attachment from a host which is not in region`);
    }
    const hostCard = card;
    card = hostCard.attachments.cards[attachmentIndex];
    const newHostCard = produce(hostCard, (draftHostCard) => {
      draftHostCard.attachments.cards.splice(attachmentIndex, 1);
    });
    newZone = produce(zone, (draftZone) => {
      draftZone.cards[index] = newHostCard;
    });
  }
  const next = produce(state, (draftState) => {
    draftState.zones[zoneName] = newZone;
  });
  return {
    next,
    card
  };
}
export function addCardToLocation(state, card, location) {
  const {zoneName, index, attachmentIndex} = location;
  const zone = getZone(state, zoneName);
  let newZone;
  if (attachmentIndex === void 0) {
    newZone = produce(zone, (draftZone) => {
      draftZone.cards.splice(index, 0, card);
    });
  } else {
    const hostCard = zone.cards[index];
    if (card.tag !== "cardInRegion") {
      throw new Error(`cannot add as attachment a card which is not cardInRegion`);
    }
    if (hostCard.tag !== "cardInRegion") {
      throw new Error(`cannot add as attachment to a host which is not cardInRegion`);
    }
    const newHostCard = produce(hostCard, (draftHostCard) => {
      draftHostCard.attachments.cards.splice(attachmentIndex, 0, card);
    });
    newZone = produce(zone, (draftZone) => {
      draftZone.cards[index] = newHostCard;
    });
  }
  const next = produce(state, (draftState) => {
    draftState.zones[zoneName] = newZone;
  });
  return {
    next
  };
}
export function removeCardAtLocationCtx(ctx, location) {
  const {state} = ctx;
  const {next, card} = removeCardAtLocation(state, location);
  ctx.state = next;
  return card;
}
export function addCardToLocationCtx(ctx, card, location) {
  const {state} = ctx;
  const {next} = addCardToLocation(state, card, location);
  ctx.state = next;
  return card;
}
export function promoteToCardInRegion(card, options = {}) {
  return {
    ...card,
    tag: "cardInRegion",
    facing: options.facing || "faceup",
    rotation: options.rotation || "ready",
    vars: {},
    attachments: createAttachmentsRegion(card.sid, card.owner)
  };
}
export function ensureCardInRegion(card, options = {}) {
  if (card.tag === "cardInBunch") {
    return promoteToCardInRegion(card, options);
  } else if (card.tag === "cardInRegion") {
    const result = produce(card, (draft) => {
      if (options.facing !== void 0) {
        draft.facing = options.facing;
      }
      if (options.rotation !== void 0) {
        draft.rotation = options.rotation;
      }
    });
    return result;
  } else {
    throw new EError(`card must be cardInBunch or cardInRegion`, card);
  }
}
export function demoteToCardInBunch(card) {
  if (card.attachments.cards.length > 0) {
    throw new EError(`Cannot move demote card with attachments`, card);
  }
  const {sid, owner, roleToKnowledge, cardBack} = card;
  return {
    tag: "cardInBunch",
    sid,
    owner,
    roleToKnowledge,
    cardBack
  };
}
export function ensureCardInBunch(card) {
  if (card.tag === "cardInBunch") {
    return card;
  } else {
    return demoteToCardInBunch(card);
  }
}
export function updateCardAtLocation(state, location, updateFn) {
  const ctx = {state};
  const card = removeCardAtLocationCtx(ctx, location);
  const newCard = updateFn(card);
  addCardToLocationCtx(ctx, newCard, location);
  return {
    next: ctx.state,
    newCard
  };
}
export function registerCardChangeCtx(ctx, card, facing, zone) {
  const {state} = ctx;
  const {roleToKnowledge} = card;
  const {roleToCardRegistry} = state;
  const next = produce({roleToCardRegistry, roleToKnowledge}, (draft) => {
    for (const [role, registry] of Object.entries(roleToCardRegistry)) {
      const previousKnowledge = roleToKnowledge[role] || null;
      const [newCardRegistry, knowledge] = registerCardChange(registry, card, facing, zone);
      if (newCardRegistry !== registry) {
        draft.roleToCardRegistry[role] = newCardRegistry;
      }
      if (knowledge !== previousKnowledge) {
        if (knowledge === null) {
          delete draft.roleToKnowledge[role];
        } else {
          draft.roleToKnowledge[role] = knowledge;
        }
      }
    }
  });
  if (next.roleToCardRegistry !== roleToCardRegistry) {
    ctx.state = produce(state, (draftState) => {
      draftState.roleToCardRegistry = next.roleToCardRegistry;
    });
  }
  const newCard = next.roleToKnowledge === roleToKnowledge ? card : {
    ...card,
    roleToKnowledge: next.roleToKnowledge
  };
  return newCard;
}
export function saveToStashCtx(ctx, key, value) {
  ctx.state = produce(ctx.state, (draftState) => {
    draftState.stash = {
      ...draftState.stash,
      [key]: value
    };
  });
}
