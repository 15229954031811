import React from "../../_snowpack/pkg/react.js";
import clsx from "../../_snowpack/pkg/clsx.js";
export function CardRow(props) {
  const {isAccessibleHand, hasSpacedCards, isAttachments} = props;
  const className = clsx("CardRow", {
    isAccessibleHand,
    hasSpacedCards,
    isAttachments
  });
  return /* @__PURE__ */ React.createElement("div", {
    className
  }, props.children);
}
