import {createSlice, createSelector} from "../../../_snowpack/pkg/@reduxjs/toolkit.js";
const homeSlice = createSlice({
  name: "home",
  initialState: {
    soloGames: null
  },
  reducers: {
    homeHello: (state, action) => {
      const {soloGames} = action.payload;
      state.soloGames = soloGames;
    }
  }
});
export const {homeHello} = homeSlice.actions;
export const homeReducer = homeSlice.reducer;
export const selectHome = (state) => state.home;
export const selectHomeSoloGames = createSelector(selectHome, (home) => home.soloGames);
