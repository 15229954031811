import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';
import.meta.env = __SNOWPACK_ENV__;

import { configureStore } from '../_snowpack/pkg/@reduxjs/toolkit.js';

import io from '../_snowpack/pkg/socket.io-client.js';
import { setConnectionStatus } from './actions.js';
import { mvReducer, createMvMessageHandler } from './slices/mv/mvSlice.js';
import { createCommonReducer, selectGameId } from './slices/common/commonSlice.js';
import { orderMenuReducer } from './slices/orderMenu/orderMenuSlice.js';
import { homeReducer, homeHello } from './slices/home/homeSlice.js';

const logger = (store) => (next) => (action) => {
  console.group(action.type);
  console.info('dispatching', action);
  let result = next(action);
  console.debug('next state', store.getState());
  console.groupEnd();
  return result;
};

// XXX I don't know why this works, but without it __SNOWPACK_ENV__ is
// undefined.
__SNOWPACK_ENV__;
const isInDevelopment = __SNOWPACK_ENV__.MODE === 'development';

function getSocketIoUrl() {
  return window.location.origin;
}

function retrieveSessionId() {
  return localStorage.getItem('bwSessionId');
}

function storeSessionId(value) {
  localStorage.setItem('bwSessionId', value);
}

class WebsocketHandler {
  constructor(handlerCreators = []) {
    this.handlers = handlerCreators.map((createHandler) => createHandler(this));
  }

  setStoreAndConnect(store) {
    this.store = store;
    this.dispatch = store.dispatch;
    this.connect();
  }

  connect() {
    const socketIoUrl = getSocketIoUrl();
    var socket = io(socketIoUrl, {
      extraHeaders: {
        bw: retrieveSessionId(),
      },
    });
    socket.on('sessionId', this.onReceiveSessionId.bind(this));
    socket.on('connect', this.onConnect.bind(this));
    socket.on('message', this.onMessage.bind(this));
    socket.on('disconnect', this.onDisconnect.bind(this));

    this.socket = socket;
  }

  onReceiveSessionId(sessionId) {
    console.log('onReceiveSessionId', sessionId);
    storeSessionId(sessionId);
  }

  disconnect() {
    this.socket.disconnect();
  }

  onConnect() {
    this.dispatch(setConnectionStatus('connected'));
    const gameId = selectGameId(this.store.getState());
    if (gameId) {
      this.sendMessage({ tag: 'requestGameId', gameId });
    }
  }

  onMessage(data) {
    const message = JSON.parse(data);
    const { tag } = message;

    console.debug('onMessage', message);
    let handled = false;
    for (const handler of this.handlers) {
      handled = handled || handler.handleMessage(message);
    }
    switch (tag) {
      case 'enterGameRoom': {
        this.dispatch({
          type: tag,
          ...message,
        });
        break;
      }
      case 'main/hello': {
        const { soloGames } = message;
        this.dispatch(
          homeHello({
            soloGames,
          })
        );
        break;
      }
      default: {
        if (!handled) {
          console.warn('Unrecognized tag of server message', tag, message);
        }
      }
    }
  }

  onDisconnect() {
    this.dispatch(setConnectionStatus('disconnected'));
  }

  sendMessage(message) {
    console.debug('sendMessage', message);
    const text = JSON.stringify(message);
    this.socket.emit('message', text);
  }
}

let wsHandler = new WebsocketHandler([createMvMessageHandler]);

export const store = configureStore({
  reducer: {
    common: createCommonReducer(wsHandler),
    mv: mvReducer,
    orderMenu: orderMenuReducer,
    home: homeReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

if (isInDevelopment) {
  undefined /* [snowpack] import.meta.hot */ .disposeCallbacks.push(() => {
    wsHandler.disconnect();
  });
}

wsHandler.setStoreAndConnect(store);
