import {createSelector} from "../_snowpack/pkg/reselect.js";
import {selectGameId} from "./slices/common/commonSlice.js";
import {getGameTagFromId} from "../_snowpack/link/engine/src/main.js";
import {
  selectYourRole as mv_selectYourRole,
  selectRoles as mv_selectRoles,
  selectRoleToPlayerName as mv_selectRoleToPlayerName
} from "./slices/mv/mvSlice.js";
function makeGameTagSelector(selectMv) {
  return createSelector([selectGameId, selectMv], (gameId, mv) => {
    const gameTag = getGameTagFromId(gameId);
    if (!gameTag) {
      return null;
    }
    if (gameTag === "mv") {
      return mv;
    }
    console.error("Must have mv but got", {gameId, gameTag, mv});
    return null;
  });
}
export const selectYourRole = makeGameTagSelector(mv_selectYourRole);
export const selectRoles = makeGameTagSelector(mv_selectRoles);
export const selectRoleToPlayerName = makeGameTagSelector(mv_selectRoleToPlayerName);
