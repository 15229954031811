import React from "../../_snowpack/pkg/react.js";
import {useCallback} from "../../_snowpack/pkg/react.js";
import {useSelector} from "../../_snowpack/pkg/react-redux.js";
import {useSendMessage} from "../hooks/useSendMessage.js";
import {selectYourRole} from "../selectors.js";
import {Navbar} from "./Navbar.js";
import {Container} from "./Container.js";
import {LeftColumn} from "./LeftColumn.js";
import {RightColumn} from "./RightColumn.js";
import {ContextBasedBigCard} from "./ContextBasedBigCard.js";
import {BigCardProvider} from "./BigCardProvider.js";
import {History} from "./History.js";
import {selectConnectionStatus} from "../slices/common/commonSlice.js";
import {selectHistoryEntries} from "../slices/mv/mvSlice.js";
import {MvGame} from "./Mv/MvGame.js";
export function DisconnectionNotice() {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, "disconnected from game server; trying to reconnect shortly");
}
export function MvGameUi(props) {
  const historyEntries = useSelector(selectHistoryEntries);
  const sendMessage = useSendMessage();
  const yourRole = useSelector(selectYourRole);
  const setTab = (value) => {
    sendMessage({
      tag: "assumePovRole",
      role: value
    });
  };
  const handleTextEntered = useCallback((text) => {
    text = text.trim();
    if (!text) {
      return;
    }
    if (text[0] === "/") {
      const [command, ...args] = text.slice(1).split(/\s+/);
      switch (command) {
        case "ping": {
          sendMessage({
            tag: "ping"
          });
          return;
        }
        case "pov": {
          sendMessage({
            tag: "assumePov",
            playerName: args[0]
          });
          return;
        }
        case "new": {
          sendMessage({
            tag: "newGame"
          });
          break;
        }
        default: {
          console.debug("to handle command", command, args);
          break;
        }
      }
    } else {
      sendMessage({
        tag: "chatLineFromClient",
        text
      });
    }
  }, [sendMessage]);
  const connectionStatus = useSelector(selectConnectionStatus);
  return /* @__PURE__ */ React.createElement(BigCardProvider, null, /* @__PURE__ */ React.createElement(Navbar, {
    tab: yourRole,
    setTab
  }), /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(LeftColumn, null, connectionStatus === "connected" ? /* @__PURE__ */ React.createElement(MvGame, null) : /* @__PURE__ */ React.createElement(DisconnectionNotice, null)), /* @__PURE__ */ React.createElement(RightColumn, null, /* @__PURE__ */ React.createElement(History, {
    onTextEntered: handleTextEntered,
    historyEntries,
    gameTag: "mv",
    yourRole
  })), /* @__PURE__ */ React.createElement(ContextBasedBigCard, null)));
}
