import React from "../../_snowpack/pkg/react.js";
import {useContext} from "../../_snowpack/pkg/react.js";
import {useDispatch} from "../../_snowpack/pkg/react-redux.js";
import {setCursorOnTrigger as oldAction} from "../actions.js";
import {BigCardContext} from "./BigCardProvider.js";
export function CardLink(props) {
  const {displayedName, cid, imageName, gameTag} = props;
  const dispatch = useDispatch();
  const {setCursorOnTrigger} = useContext(BigCardContext);
  if (!imageName) {
    return /* @__PURE__ */ React.createElement("b", null, displayedName);
  }
  const handleMouseEnter = (event) => {
    if (gameTag === "mv") {
      setCursorOnTrigger(true, imageName, event);
    } else {
      dispatch(oldAction(true, imageName));
    }
  };
  const handleMouseLeave = () => {
    if (gameTag === "mv") {
      setCursorOnTrigger(false);
    } else {
      dispatch(oldAction(false));
    }
  };
  return /* @__PURE__ */ React.createElement("a", {
    href: `#${cid}`,
    onClick: (e) => e.preventDefault(),
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave
  }, displayedName);
}
