import React, {useState} from "../../../_snowpack/pkg/react.js";
import clsx from "../../../_snowpack/pkg/clsx.js";
import {useSelector, useDispatch} from "../../../_snowpack/pkg/react-redux.js";
import {
  createSelectOrderRowsBySource,
  createSelectVOrdersBySource,
  selectRoleToPlayerName
} from "../../slices/mv/mvSlice.js";
import {useSendMessage} from "../../hooks/useSendMessage.js";
import {
  mvOrderLabelTemplates,
  cardForHumansFromYourKnowledge
} from "../../translations.js";
import {
  closeOrderMenu,
  createSelectOrderMenuIsOpenForSource,
  selectOrderMenuIsOpen
} from "../../slices/orderMenu/orderMenuSlice.js";
import {PopupWrapper} from "../PopupWrapper.js";
import {getKeyFromVOrder} from "../../slices/mv/mvUtils.js";
export function getIn(map, keys, defaultValue) {
  let current = map;
  for (const key of keys) {
    if (!current[key]) {
      return defaultValue;
    }
    current = current[key];
  }
  return current;
}
export function getOrderLabel(vOrder) {
  const {orderName: fullName} = vOrder;
  const [groupName, orderName] = fullName.split("/");
  const labelTemplate = getIn(mvOrderLabelTemplates, [groupName, orderName], `?${orderName}`);
  return labelTemplate;
}
export const NextLevelMenu = (props) => {
  const {
    nextLevelOrders,
    isOpen,
    onDismiss,
    parentVOrder,
    dismissWholeOrderMenu,
    stickTo
  } = props;
  const roleToPlayerName = useSelector(selectRoleToPlayerName);
  const sendMessage = useSendMessage();
  const handleOrderClick = (order) => {
    const filledOrder = {
      orderName: parentVOrder.orderName,
      source: parentVOrder.source,
      nextLevelSource: order.source
    };
    dismissWholeOrderMenu();
    sendMessage({
      tag: "mv/filledOrder",
      filledOrder
    });
  };
  const handleDismiss = () => {
    onDismiss();
  };
  return /* @__PURE__ */ React.createElement(PopupWrapper, {
    isActive: isOpen,
    onDismiss: handleDismiss
  }, /* @__PURE__ */ React.createElement("ul", {
    className: clsx("NextLevelMenu", {isOpen, stickTo})
  }, nextLevelOrders.map((order, index) => {
    const {source} = order;
    const cid = source.split(":")[1];
    const {displayedName} = cardForHumansFromYourKnowledge({knowledgeType: "full", cid}, roleToPlayerName);
    return /* @__PURE__ */ React.createElement("li", {
      key: cid,
      className: "OrderRow"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "order",
      tabIndex: 0,
      onClick: () => handleOrderClick(order)
    }, displayedName));
  })));
};
export const MvOrderMenu = (props) => {
  const {
    source,
    isStatic = false,
    isOpen,
    stickToBottom,
    dismiss = null,
    stickNextLevelMenuTo = "right",
    shouldLookDisabled
  } = props;
  const [nextLevelOpenId, setNextLevelOpenId] = useState(null);
  const orderRows = useSelector(createSelectOrderRowsBySource(source));
  const sendMessage = useSendMessage();
  function handleOrderClick(vOrder) {
    console.debug("order clicked", vOrder);
    const {orderName, orderType, source: source2, isDisabled, isRepeatable} = vOrder;
    let filledOrder;
    if (isDisabled) {
      return;
    }
    switch (orderType) {
      case "simple": {
        filledOrder = {
          orderName
        };
        break;
      }
      case "simpleOnCard":
      case "simpleOnPile":
      case "simpleOnPlayer": {
        filledOrder = {
          orderName,
          source: source2
        };
        break;
      }
      case "cardWithAnotherCard": {
        const id = vOrder.orderName;
        setNextLevelOpenId(nextLevelOpenId === id ? null : id);
        break;
      }
      default: {
        console.error("Unrecognized orderType of a clicked vOrder", vOrder);
        filledOrder = null;
        break;
      }
    }
    if (filledOrder) {
      if (!isRepeatable && dismiss) {
        dismiss();
      }
      sendMessage({
        tag: "mv/filledOrder",
        filledOrder
      });
    }
  }
  function handleKeyDown(event, vOrder) {
    if (event.key === "Enter") {
      handleOrderClick(vOrder);
    }
  }
  if (orderRows.length === 0) {
    return null;
  }
  return /* @__PURE__ */ React.createElement("ul", {
    className: clsx("OrderMenu", {
      isStatic,
      isOpen,
      stickToBottom,
      shouldLookDisabled
    })
  }, orderRows.map((orderRow) => {
    const {key, orders} = orderRow;
    return /* @__PURE__ */ React.createElement("li", {
      key,
      className: "OrderRow"
    }, orders.map((vOrder, index) => {
      const {isDisabled, nextLevelOrders} = vOrder;
      const id = vOrder.orderName;
      const orderKey = getKeyFromVOrder(vOrder);
      const hasNextLevelOrders = !!nextLevelOrders;
      return /* @__PURE__ */ React.createElement(React.Fragment, {
        key: orderKey
      }, /* @__PURE__ */ React.createElement("div", {
        key: orderKey,
        className: clsx("order", {
          isDisabled: isDisabled || shouldLookDisabled,
          hasNextLevelOrders
        }),
        tabIndex: 0,
        onClick: () => handleOrderClick(vOrder),
        onKeyDown: (event) => handleKeyDown(event, vOrder)
      }, getOrderLabel(vOrder)), hasNextLevelOrders ? /* @__PURE__ */ React.createElement(NextLevelMenu, {
        isOpen: nextLevelOpenId === id,
        nextLevelOrders,
        onDismiss: () => setNextLevelOpenId(null),
        parentVOrder: vOrder,
        dismissWholeOrderMenu: dismiss,
        stickTo: stickNextLevelMenuTo
      }) : null);
    }));
  }));
};
export function MvGeneralOrderMenu(props) {
  const shouldLookDisabled = useSelector(selectOrderMenuIsOpen);
  return /* @__PURE__ */ React.createElement(MvOrderMenu, {
    source: "general",
    isStatic: true,
    isOpen: true,
    stickNextLevelMenuTo: "left",
    shouldLookDisabled
  });
}
export function MvPopupOrderMenu(props) {
  const {source, stickToBottom} = props;
  const vOrders = useSelector(createSelectVOrdersBySource(source));
  const isOpen = useSelector(createSelectOrderMenuIsOpenForSource(source));
  const dispatch = useDispatch();
  function handleDismiss() {
    dispatch(closeOrderMenu({}));
  }
  if (vOrders.length === 0) {
    return null;
  }
  return /* @__PURE__ */ React.createElement(PopupWrapper, {
    isActive: isOpen,
    onDismiss: handleDismiss
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(MvOrderMenu, {
    source,
    isOpen,
    stickToBottom,
    dismiss: handleDismiss
  })));
}
