import React from "../../_snowpack/pkg/react.js";
export function PlayerSection(props) {
  const {playerName} = props;
  return /* @__PURE__ */ React.createElement("div", {
    className: "PlayerSection"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "playerName"
  }, playerName), /* @__PURE__ */ React.createElement("div", {
    className: "chevron"
  }, "^"), props.children);
}
