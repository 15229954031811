import {
  getYourKnowledge,
  getZoneName,
  buildZoneName
} from "./utils.js";
import {EError} from "./errors.js";
const allGroups = {};
export function createOrderGroup(name, options, descriptors) {
  return {
    name,
    options,
    descriptors
  };
}
export function registerOrderGroup(group) {
  const {name} = group;
  allGroups[name] = group;
}
export const generalSource = "general";
export function makeCardSource(card) {
  return {
    sourceType: "card",
    card
  };
}
export function makePileSourceFromRoleAndSlug(role, slug) {
  return {
    sourceType: "pile",
    zoneName: buildZoneName(role, slug)
  };
}
export function makePileSource(zone) {
  return {
    sourceType: "pile",
    zoneName: getZoneName(zone)
  };
}
export function makePlayerSource(role) {
  return {
    sourceType: "player",
    role
  };
}
export function collectOrders(state) {
  const result = [];
  Object.entries(allGroups).forEach(([groupName, group]) => {
    const {options, descriptors} = group;
    if (options.precondition) {
      if (!options.precondition(state)) {
        return;
      }
    }
    Object.entries(descriptors).forEach(([orderName, orderDescriptor]) => {
      const {collect} = orderDescriptor;
      const collectionResult = collect(state);
      const ordersWithImpliedName = collectionResult === null ? [] : Array.isArray(collectionResult) ? collectionResult.filter((x) => !!x) : [collectionResult];
      const fullName = `${groupName}/${orderName}`;
      const namedOrders = ordersWithImpliedName.map((orderWithImpliedName) => ({
        ...orderWithImpliedName,
        orderName: fullName
      }));
      result.push(...namedOrders);
    });
  });
  return result;
}
export function makeCardVSource(cid) {
  return `card:${cid}`;
}
export function makePileVSource(owner, slug) {
  return `pile:${buildZoneName(owner, slug)}`;
}
export function makePlayerVSource(role) {
  return `player:${role}`;
}
export function orderSourceToVOrderSource(source, pov) {
  if (source === "general") {
    return source;
  }
  switch (source.sourceType) {
    case "player": {
      return `player:${source.role}`;
    }
    case "card": {
      const yourKnowledge = getYourKnowledge(source.card, pov);
      if (yourKnowledge === null) {
        return null;
      }
      return makeCardVSource(yourKnowledge.cid);
    }
    case "pile": {
      const {zoneName} = source;
      return `pile:${zoneName}`;
    }
  }
}
export function nextLevelOrderToVNextLevelOrder(order, pov) {
  const {orderType, orderName, source} = order;
  const vSource = orderSourceToVOrderSource(source, pov);
  if (vSource === null) {
    return null;
  }
  return {
    orderType,
    orderName,
    source: vSource
  };
}
export function orderToVOrder(order, pov) {
  const {role, orderType, orderName, source} = order;
  const vSource = orderSourceToVOrderSource(source, pov);
  if (vSource === null) {
    throw new EError(`cannot conert order to vOrder`, {
      source,
      order
    });
  }
  const result = {
    orderType,
    orderName,
    source: vSource
  };
  if (order.isDisabled) {
    result.isDisabled = true;
  }
  if (order.isRepeatable) {
    result.isRepeatable = true;
  }
  if (!!order.orderRow) {
    result.orderRow = order.orderRow;
  }
  if (!!order.nextLevelOrders) {
    result.nextLevelOrders = order.nextLevelOrders.map((nextLevelOrder) => {
      const vNextLevelOrder = nextLevelOrderToVNextLevelOrder(nextLevelOrder, pov);
      if (vNextLevelOrder === null) {
        throw new EError(`cannot conert order to vOrder because of next level`, {
          source,
          order,
          nextLevelOrder
        });
      }
      return vNextLevelOrder;
    });
  }
  return result;
}
export function ordersToVOrders(orders, pov) {
  return orders.filter((order) => order.role === pov).map((order) => orderToVOrder(order, pov));
}
export function executeFilledOrder(state, order, filledOrder) {
  const {orderName: fullName} = order;
  const [groupName, orderName] = fullName.split("/");
  const orderDefinition = allGroups[groupName].descriptors[orderName];
  if (!orderDefinition) {
    throw new EError("Cannot find order definition for order", order);
  }
  if (order.isDisabled) {
    throw new EError("Cannot execute disabled order", order);
  }
  return orderDefinition.execute(state, order, filledOrder);
}
