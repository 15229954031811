import {
  createActionGroup,
  registerActionGroup,
  applyAction
} from "../actionsInfrastructure.js";
import {fa} from "../foundationActions.js";
import {ActionError} from "../errors.js";
import {
  getZone,
  qualifiedName,
  getStateVar,
  getNumberStateVar,
  getCardsInPlayerZone,
  getZoneName,
  findCardLocation,
  getCardByLocation,
  buildZoneName,
  walkCards
} from "../utils.js";
import {inPlaySlugs} from "./mvConstants.js";
import {getPlayingRoles, getActivePlayerRole} from "./mvUtils.js";
export const mvGroup = createActionGroup({
  drawCard(state, role, options = {}) {
    const zone = getZone(state, qualifiedName(role, "library"));
    if (zone.cards.length === 0) {
      throw new ActionError(`library must not be empty`);
    }
    const card = zone.cards[0];
    return applyAction(state, fa.moveCard(card.sid, qualifiedName(role, "hand"), {
      sourceLocation: {
        zoneName: getZoneName(zone),
        index: 0
      },
      saveAs: options.saveAs
    }));
  },
  recruit(state, role, options = {}) {
    const zone = getZone(state, qualifiedName(role, "crypt"));
    if (zone.cards.length === 0) {
      throw new ActionError(`crypt must not be empty`);
    }
    const card = zone.cards[0];
    return applyAction(state, fa.seq(fa.moveCard(card.sid, qualifiedName(role, "uncontrolled"), {
      facing: "facedown",
      saveTargetLocationAs: "location",
      saveAs: options.saveAs
    }), fa.setCardVars(card.sid, {blood: 0})));
  },
  startGame(state) {
    const {roles} = state;
    const drawActions = roles.map((role) => {
      const actions = [
        fa.shuffleZone(qualifiedName(role, "crypt")),
        fa.shuffleZone(qualifiedName(role, "library"))
      ];
      actions.push(fa.repeat(mva.drawCard(role), 7));
      actions.push(fa.repeat(mva.recruit(role), 4));
      return fa.seq(...actions);
    });
    return applyAction(state, fa.seq(fa.setVars({
      specialSequence: null,
      searchingLibraryRole: null,
      searchingCryptRole: null,
      actionInProgress: false
    }), ...drawActions, mva.reportStartGame()));
  },
  reportStartGame(state) {
    const {roles, roleToPlayerName} = state;
    const playerNames = roles.map((role) => roleToPlayerName[role]);
    return applyAction(state, fa.text("t-reportStartGame", {
      a: {value: playerNames}
    }));
  },
  endTurn(state) {
    const activePlayerRole = getActivePlayerRole(state);
    const round = getNumberStateVar(state, "round");
    const roles = getPlayingRoles(state);
    const index = roles.findIndex((role) => role === activePlayerRole);
    let newIndex, newRound;
    if (index + 1 === roles.length) {
      newIndex = 0;
      newRound = round + 1;
    } else {
      newIndex = index + 1;
      newRound = round;
    }
    const newRole = roles[newIndex];
    const newVars = {activePlayerRole: newRole};
    if (newRound !== round) {
      newVars["round"] = newRound;
    }
    const pool = getStateVar(state, `${newRole}:pool`);
    return applyAction(state, fa.seq(fa.setVars(newVars), fa.text("t-reportStartTurn", {
      r: {value: newRole},
      n1: {value: newRound},
      n2: {value: pool}
    })));
  },
  discardCurrentCards(state) {
    return applyAction(state, fa.seq(...state.roles.map((role) => {
      return fa.seq(...getCardsInPlayerZone(state, role, "current").map((card) => {
        return fa.moveCard(card.sid, qualifiedName(card.owner, "ashHeap"), {
          sourceLocation: {
            zoneName: qualifiedName(role, "current"),
            index: 0
          }
        });
      }));
    })));
  },
  changePool(state, role, amount, options = {}) {
    return applyAction(state, fa.changeSingleVar(qualifiedName(role, "pool"), amount, options));
  },
  changeVp(state, role, amount, options = {}) {
    return applyAction(state, fa.changeSingleVar(qualifiedName(role, "vp"), amount, options));
  },
  burnCard(state, sid, options = {}) {
    const location = options.location || findCardLocation(state, sid);
    const card = getCardByLocation(state, location);
    const burnAttachments = card.tag === "cardInRegion" ? card.attachments.cards.map((attachedCard, attachmentIndex) => {
      return mva.burnCard(attachedCard.sid);
    }) : [];
    return applyAction(state, fa.seq(...burnAttachments, fa.moveCard(card.sid, buildZoneName(card.owner, "ashHeap"), {
      sourceLocation: location,
      saveAs: options.saveAs
    })));
  },
  oustPlayer(state, oustedRole) {
    const burnAttachedCards = walkCards(state, {
      roles: getPlayingRoles(state),
      slugs: inPlaySlugs,
      onlyAttachments: true
    }, (card, location, role) => {
      if (role !== oustedRole) {
        return null;
      }
      return mva.burnCard(card.sid);
    });
    const burnRootCards = walkCards(state, {
      roles: getPlayingRoles(state),
      slugs: [...inPlaySlugs, "current", "hand", "uncontrolled"],
      skipAttachments: true
    }, (card, location, role) => {
      if (role !== oustedRole) {
        return null;
      }
      return mva.burnCard(card.sid);
    });
    return applyAction(state, fa.seq(...burnAttachedCards, ...burnRootCards, fa.setSingleVar(qualifiedName("ousted", oustedRole), 1)));
  },
  lockCard(state, sid, options = {}) {
    return applyAction(state, fa.changeCardRotation(sid, "locked", {
      location: options.location,
      saveAs: options.saveAs
    }));
  },
  unlockCard(state, sid, options = {}) {
    return applyAction(state, fa.changeCardRotation(sid, "ready", {
      location: options.location,
      saveAs: options.saveAs
    }));
  }
});
export const mva = mvGroup.actionCreators;
registerActionGroup(mvGroup);
