import React from "../../../_snowpack/pkg/react.js";
import clsx from "../../../_snowpack/pkg/clsx.js";
import {useSelector, useDispatch} from "../../../_snowpack/pkg/react-redux.js";
import {
  createSelectPlayerNameByRole,
  createSelectParticularStateVars,
  createSelectVOrdersBySource,
  createSelectSingleStateVar
} from "../../slices/mv/mvSlice.js";
import {qualifiedName, makePlayerVSource} from "../../../_snowpack/link/engine/src/main.js";
import {makePlayerBadgeTitle} from "../../utils.js";
import {MvPopupOrderMenu} from "./MvOrderMenu.js";
import {Sep} from "../Sep.js";
import {
  orderMenuDwim,
  createSelectOrderMenuIsOpenForDifferentSource,
  createSelectOrderMenuIsOpenForSource
} from "../../slices/orderMenu/orderMenuSlice.js";
export function MvPlayerBadge(props) {
  const {role} = props;
  const dispatch = useDispatch();
  const playerName = useSelector(createSelectPlayerNameByRole(role));
  const [pool, vp, edgeRole, activePlayerRole] = useSelector(createSelectParticularStateVars(qualifiedName(role, "pool"), qualifiedName(role, "vp"), "edgeRole", "activePlayerRole"));
  const isOusted = useSelector(createSelectSingleStateVar(qualifiedName("ousted", role)));
  const source = makePlayerVSource(role);
  const isActive = activePlayerRole === role;
  const hasEdge = edgeRole === role;
  const vOrders = useSelector(createSelectVOrdersBySource(source));
  const isActivatable = vOrders.length > 0;
  const handleClick = () => {
    dispatch(orderMenuDwim({source}));
  };
  const anotherOrderMenuIsOpen = useSelector(createSelectOrderMenuIsOpenForDifferentSource(source));
  const myOrderMenuIsOpen = useSelector(createSelectOrderMenuIsOpenForSource(source));
  const avatarSrc = `https://robohash.org/${playerName}.png?size=32x32`;
  return /* @__PURE__ */ React.createElement("div", {
    className: clsx("PlayerBadgeOuter")
  }, /* @__PURE__ */ React.createElement("div", {
    className: "chevron"
  }, "›"), /* @__PURE__ */ React.createElement("div", {
    className: clsx("PlayerBadgeInner", {
      isActivatable,
      myOrderMenuIsOpen,
      anotherOrderMenuIsOpen,
      isActive,
      isOusted
    }),
    title: makePlayerBadgeTitle({
      "Has the edge": hasEdge,
      "Current turn": isActive,
      Ousted: isOusted
    }),
    onClick: handleClick
  }, /* @__PURE__ */ React.createElement("div", {
    className: "avatar"
  }, /* @__PURE__ */ React.createElement("img", {
    alt: playerName,
    src: avatarSrc
  })), isOusted ? /* @__PURE__ */ React.createElement("div", {
    className: "vars"
  }, /* @__PURE__ */ React.createElement("b", {
    className: "playerName"
  }, playerName), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("span", null, vp), " vp") : /* @__PURE__ */ React.createElement("div", {
    className: "vars"
  }, hasEdge && "◎", " ", /* @__PURE__ */ React.createElement("b", {
    className: "playerName"
  }, playerName), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("span", null, pool), " pool", /* @__PURE__ */ React.createElement(Sep, null), /* @__PURE__ */ React.createElement("span", null, vp), " vp")), /* @__PURE__ */ React.createElement(MvPopupOrderMenu, {
    source
  }));
}
