import React from "../../../_snowpack/pkg/react.js";
import {MvSmallCard} from "./MvSmallCard.js";
export function MvCardList(props) {
  const {cards, stickOrderMenuToBottom, isInert, stickNextLevelMenuTo} = props;
  return cards.map((card, index) => {
    const {yourKnowledge, cardBack, tag} = card;
    const key = yourKnowledge === null ? index : yourKnowledge.cid;
    if (tag === "cardInBunch") {
      return /* @__PURE__ */ React.createElement(MvSmallCard, {
        key,
        yourKnowledge,
        cardBack,
        stickOrderMenuToBottom,
        isInert
      });
    } else {
      const {facing, rotation, vars, attachedCards} = card;
      return /* @__PURE__ */ React.createElement(MvSmallCard, {
        key,
        yourKnowledge,
        cardBack,
        facing,
        rotation,
        vars,
        stickOrderMenuToBottom,
        isInert,
        attachedCards,
        stickNextLevelMenuTo
      });
    }
  });
}
