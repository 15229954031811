import {ActionError, EError} from "./errors.js";
export function getZoneYourAccess(access, pov) {
  const result = access === "all" || access.includes(pov) ? "accessible" : "inaccessible";
  return result;
}
export function indent(level) {
  let result = "";
  for (let i = 0; i < level; ++i) {
    result += "  ";
  }
  return result;
}
export function stringComparator(a, b) {
  return a < b ? -1 : a === b ? 0 : 1;
}
export function getZoneOfLocation(state, location) {
  const {zoneName, index, attachmentIndex} = location;
  if (attachmentIndex === void 0) {
    return state.zones[zoneName];
  } else {
    return state.zones[zoneName].cards[index].attachments;
  }
}
export function getZone(state, zoneName) {
  const zone = state.zones[zoneName];
  return zone;
}
export function createAttachmentsRegion(sid, owner) {
  return {
    zoneKind: "region",
    owner,
    slug: `attachments:${sid}`,
    access: "all",
    cards: [],
    defaultCardBack: null
  };
}
export function findCardLocation(state, sid) {
  for (const [zoneName, zone] of Object.entries(state.zones)) {
    const {cards, zoneKind} = zone;
    for (let index = 0; index < cards.length; ++index) {
      const card = cards[index];
      if (card.sid === sid) {
        return {
          zoneName,
          index
        };
      }
      if (zoneKind === "region") {
        const {attachments} = card;
        const attachmentIndex = attachments.cards.findIndex((attachment) => attachment.sid === sid);
        if (attachmentIndex !== -1) {
          return {
            zoneName,
            index,
            attachmentIndex
          };
        }
      }
    }
  }
  return null;
}
export function mapValues(obj, convertValue = (x, key) => x) {
  const result = {};
  Object.entries(obj).forEach(([k, v]) => {
    result[k] = convertValue(v, k);
  });
  return result;
}
export function getYourKnowledge(card, pov) {
  return card.roleToKnowledge[pov] || null;
}
export function indentedList(header, items, level) {
  const lines = [header, ...items.map((item) => `${indent(level)}- ${item}`)];
  const result = lines.join("\n");
  return result;
}
export function entriesSortedAlphabetically(obj) {
  return Object.entries(obj).sort((a, b) => {
    return stringComparator(a[0], b[0]);
  });
}
export function buildZoneName(owner, slug) {
  return owner ? `${owner}:${slug}` : slug;
}
export function getZoneName(zone) {
  const {owner, slug} = zone;
  return buildZoneName(owner, slug);
}
export function splitZoneName(zoneName) {
  const [owner, slug] = zoneName.split(":");
  return [owner, slug];
}
export function getTranslatedCidFromKnowledge(knowledge) {
  let translatedCid;
  if (knowledge === void 0) {
    throw new Error("how can that be");
  }
  if (knowledge === null) {
    translatedCid = "?";
  } else {
    translatedCid = knowledge.cid;
  }
  return translatedCid;
}
export function getCardTranslatedCid(card, pov) {
  const {roleToKnowledge} = card;
  const knowledge = roleToKnowledge[pov] || null;
  return getTranslatedCidFromKnowledge(knowledge);
}
export function getCardByLocation(state, location) {
  const {zoneName, index, attachmentIndex} = location;
  const card = getZone(state, zoneName).cards[index];
  if (attachmentIndex === void 0) {
    return card;
  }
  if (card.tag !== "cardInRegion") {
    throw new EError(`host card must be in region`, location);
  }
  return card.attachments.cards[attachmentIndex];
}
export function entryToValue([_k, v]) {
  return v;
}
export function qualifiedName(role, name) {
  return `${role}:${name}`;
}
export function shuffleArray(array) {
  const result = [...array];
  let currentIndex = array.length, randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [result[currentIndex], result[randomIndex]] = [
      result[randomIndex],
      result[currentIndex]
    ];
  }
  return result;
}
export function getStateVar(state, varName, defaultValue) {
  const result = state.vars[varName];
  if (result === void 0) {
    if (defaultValue !== void 0) {
      return defaultValue;
    } else {
      throw new ActionError(`state var ${varName} doesn't exist`);
    }
  }
  return result;
}
export function getNumberStateVar(state, varName) {
  const result = state.vars[varName];
  if (result === void 0) {
    throw new ActionError(`state var ${varName} doesn't exist`);
  }
  if (typeof result !== "number") {
    throw new ActionError(`state var ${varName} must be a number: ${result}`);
  }
  return result;
}
export function getGameTagFromId(gameId) {
  return gameId.split("-")[0];
}
export function isEmpty(value) {
  return value === null || value === void 0;
}
export function getPlayerZone(state, role, slug) {
  const zoneName = buildZoneName(role, slug);
  return state.zones[zoneName];
}
export function getCardsInPlayerZone(state, role, slug) {
  const zoneName = buildZoneName(role, slug);
  return state.zones[zoneName].cards;
}
export function locationIsAttachment(location) {
  return location.attachmentIndex !== void 0;
}
export function walkCards(state, config, walker) {
  const roleOrRoles = config.roles;
  const slugOrSlugs = config.slugs;
  const roles = typeof roleOrRoles === "string" ? [roleOrRoles] : roleOrRoles;
  const slugs = typeof slugOrSlugs === "string" ? [slugOrSlugs] : slugOrSlugs;
  return roles.flatMap((role) => {
    return slugs.flatMap((slug) => {
      const cards = getCardsInPlayerZone(state, role, slug);
      return cards.flatMap((card, index) => {
        const zoneName = buildZoneName(role, slug);
        const results = [];
        if (!config.onlyAttachments) {
          results.push(walker(card, {
            zoneName,
            index
          }, role));
        }
        if (card.tag === "cardInRegion" && !config.skipAttachments) {
          results.push(...card.attachments.cards.map((attachment, attachmentIndex) => {
            return walker(attachment, {
              zoneName,
              index,
              attachmentIndex
            }, role);
          }));
        }
        return results.filter((result) => result !== null);
      });
    });
  }).filter((x) => x !== null);
}
