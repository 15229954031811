import {getZoneYourAccess, getYourKnowledge} from "./utils.js";
import produce from "../../../pkg/immer.js";
export function createCardRegistry(role) {
  return {
    role,
    baseToCounter: {},
    cidToSid: {}
  };
}
function getStance(facing, yourAccess) {
  if (yourAccess === "inaccessible") {
    if (facing === null) {
      return "none";
    } else if (facing === "facedown") {
      return "lim/past";
    } else {
      return "full";
    }
  } else {
    return "full";
  }
}
function getChange(previousKnowledge, stance) {
  const prev = previousKnowledge ? previousKnowledge.knowledgeType : "none";
  if (prev === "none") {
    if (stance === "none") {
      return "preserve";
    } else if (stance === "lim/past") {
      return "create-lim";
    } else {
      return "create-full";
    }
  } else if (prev === "limited") {
    if (stance === "none") {
      return "forget";
    } else if (stance === "lim/past") {
      return "preserve";
    } else {
      return "change-to-full";
    }
  } else {
    if (stance === "none") {
      return "forget";
    } else if (stance === "lim/past") {
      return "preserve";
    } else {
      return "preserve";
    }
  }
}
function createCidFromBase(registry, base, sid) {
  const counter = registry.baseToCounter[base] || 0;
  const nextCounter = counter + 1;
  const cid = `${base}#${nextCounter}`;
  const next = produce(registry, (draft) => {
    draft.baseToCounter[base] = nextCounter;
    draft.cidToSid[cid] = sid;
  });
  return {
    next,
    cid
  };
}
function unlinkCid(registry, knowledge, sid) {
  if (knowledge === null) {
    throw new Error(`Cannot unlink cid: ${sid}`);
  }
  const next = produce(registry, (draft) => {
    delete draft.cidToSid[knowledge.cid];
  });
  return {next};
}
function createFullKnowledgeCid(registry, sid) {
  const base = sid.split(":")[0];
  return createCidFromBase(registry, base, sid);
}
function createLimitedKnowledgeCid(registry, sid, owner) {
  const base = `H${owner}`;
  return createCidFromBase(registry, base, sid);
}
function applyChange(registry, previousKnowledge, sid, owner, change) {
  switch (change) {
    case "preserve": {
      return [registry, previousKnowledge];
    }
    case "create-lim": {
      const {next, cid} = createLimitedKnowledgeCid(registry, sid, owner);
      return [next, {knowledgeType: "limited", cid}];
    }
    case "create-full": {
      const {next, cid} = createFullKnowledgeCid(registry, sid);
      return [next, {knowledgeType: "full", cid}];
    }
    case "forget": {
      const {next} = unlinkCid(registry, previousKnowledge, sid);
      return [next, null];
    }
    case "change-to-full": {
      const {next} = unlinkCid(registry, previousKnowledge, sid);
      const {next: next1, cid} = createFullKnowledgeCid(next, sid);
      return [next1, {knowledgeType: "full", cid}];
    }
  }
}
export function registerCardChange(registry, card, facing, zone) {
  const {access} = zone;
  const {owner} = card;
  const yourAccess = getZoneYourAccess(access, registry.role);
  const stance = getStance(facing, yourAccess);
  const {sid} = card;
  const previousKnowledge = getYourKnowledge(card, registry.role);
  const change = getChange(previousKnowledge, stance);
  return applyChange(registry, previousKnowledge, sid, owner, change);
}
